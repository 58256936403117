import React from 'react'
import PropTypes from 'prop-types'
import store from './store'
import { selectRelatedConnector, deselectRelatedConnector } from './actions'
import ItemRow from '../shared/ItemRow'

class RelatedConnectorRow extends React.Component {
  state = {
    checked: false
  }

  checkboxHandler = (connectorId) => {
    this.setState(
      { checked: !this.state.checked },
      () => {
        if (!this.state.checked) {
          store.dispatch(deselectRelatedConnector(connectorId))
        } else {
          store.dispatch(selectRelatedConnector(connectorId))
        }
      }
    )
  }

  render() {
    const { name, connectorId, description, harnessName } = this.props
    const inputName = 'related_connector_ids[]'

    return (
      <ItemRow
        name={name}
        inputName={inputName}
        value={connectorId}
        onChange={() => this.checkboxHandler(connectorId)}
        isChecked={this.state.checked}
        description={description}
      >
        { harnessName && <div class='text-xs break-all'>
            HS: {harnessName}
          </div> }
      </ItemRow>
    )
  }
}

RelatedConnectorRow.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  harnessName: PropTypes.string,
  connectorId: PropTypes.number.isRequired,
}

export default RelatedConnectorRow
