import React from 'react'
import PropTypes from 'prop-types'
import { CONNECTOR_TYPE } from "./actions"
import store from './store'
import LinkWithStatus from '../shared/navigation/LinkWithStatus'
import * as Routes from 'routes'

class SearchConnectorsButton extends React.Component {
  constructor() {
    super()
    this.unsubscribe = store.subscribe(this.stateChange.bind(this))
    this.state = {
      selectedPins: store.getState().selectedPins,
      connectorType: store.getState().selectedConnectorType,
      queryType: store.getState().selectedQueryType,
      circuitIds: store.getState().selectedCircuitIds,
      searchOldLevels: store.getState().selectedSearchOldLevels
    }
  }

  stateChange() {
    this.setState({
      selectedPins: store.getState().selectedPins,
      connectorType: store.getState().selectedConnectorType,
      queryType: store.getState().selectedQueryType,
      circuitIds: store.getState().selectedCircuitIds,
      searchOldLevels: store.getState().selectedSearchOldLevels
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  getUri(){
    const {selectedPins, connectorType, circuitIds, queryType, searchOldLevels} = this.state
    const {vehicleId, connectorId, params} = this.props
    const queryParams = {
      ...params, dtc_mapping_type: connectorType, query_type: queryType,
      pins: selectedPins, circuit_ids: circuitIds, search_old_levels: searchOldLevels
    }

    return Routes.vehicleDtcMappingPath(vehicleId, connectorId, queryParams)
  }

  render() {
    const uri = this.getUri()
    const text = this.state.connectorType === CONNECTOR_TYPE.end ? 'Filter Start Connectors' : 'Filter End Connectors'
    return (
      <LinkWithStatus className='btn capitalize my-4 w-full' href={uri}>
        {text}
      </LinkWithStatus>
    )
  }
}

SearchConnectorsButton.propTypes = {
  vehicleId: PropTypes.number.isRequired,
  connectorId: PropTypes.number.isRequired,
  params: PropTypes.object.isRequired,
  pins: PropTypes.array
}

export default SearchConnectorsButton
