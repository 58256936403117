import { createSlice } from '@reduxjs/toolkit'

const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState: {},
  reducers: {
    initialize: (state, { payload }) => payload,
  }
})

export const { initialize } = vehicleSlice.actions

export default vehicleSlice.reducer
