import React from 'react'
import PropTypes from 'prop-types'

const MappingItem = (props) => {
  const { connector, onClick } = props;
  let connectorTitle, markedAs

  connectorTitle = (
    <span>
      <div>
        {connector.name}
      </div>
      <div className='font-normal'>
        {connector.description}
      </div>
      {connector.auto_mapped !== null ? (
        <div className='font-normal'>
          3D Reference:&nbsp;
          {connector.reference}
        </div>
      ) : ''}
    </span>
  )

  if (connector.auto_mapped) {
    markedAs = <span className='text-red-600 pr-1'> A </span>
  }

  if (connector.edited){
    markedAs = <span className='text-red-600 pr-1'> &#9998; </span>
  }

  return (
    <div className='flex flex-col items-center bg-white w-full border-b border-tracer-dark-blue group hover:bg-blue-100'>
      <div className='flex w-full font-bold group p-2 break-all'>
        { markedAs } { connectorTitle }
      </div>
      <div
        className='object-left cursor-pointer text-blue-500 underline group-hover:block hidden font-normal w-full px-2'
        onClick={() => (onClick(connector))}
      >
        { connector.auto_mapped !== null ? 'Edit' : 'Select'}
      </div>
    </div>
  )
}

MappingItem.propTypes = {
  connector: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
}

export default MappingItem
