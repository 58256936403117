import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PowerDistributionsTable from './PowerDistributionsTable'
import Pagination from '../shared/navigation/Pagination'
import LoadingSpinnerOverlay from '../shared/LoadingSpinnerOverlay'
import ActionPanel from './ActionPanel'

const PowerDistributions = ({
  fetchPowerDistributions,
  incrementPage,
  decrementPage,
  setSearch,
  generateAllWires,
  pagination,
  status
}) => {
  const [searchValue, setSearchValue] = useState('')
  const handleAction = () => {
    setSearch(searchValue)
    fetchPowerDistributions()
  }

  useEffect(() => { if (status === 'idle') fetchPowerDistributions() }, [status, fetchPowerDistributions])
  return (
    <div className='flex flex-col py-3 space-y-6'>
      <ActionPanel
        searchValue={searchValue}
        updateSearchValue={setSearchValue}
        handleAction={handleAction}
        generateAllWires={generateAllWires}
      />
      <PowerDistributionsTable />
      <Pagination
        onClickNext={incrementPage}
        onClickPrev={decrementPage}
        context={pagination}
      />
      <LoadingSpinnerOverlay active={status === 'loading'} />
    </div>
  )
}

import { connect } from 'react-redux'
import { decrementPage, fetchPowerDistributions, incrementPage, setSearch, generateAllWires } from './slice'

const mapStateToProps = ({ powerDistributions: { pagination, status } }) => ({ pagination, status })

const mapDispatchToProps = (dispatch) => ({
  fetchPowerDistributions: () => dispatch(fetchPowerDistributions()),
  incrementPage: () => dispatch(incrementPage()),
  decrementPage: () => dispatch(decrementPage()),
  setSearch: (searchValue) => dispatch(setSearch(searchValue)),
  generateAllWires: () => dispatch(generateAllWires())
})

export default connect(mapStateToProps, mapDispatchToProps)(PowerDistributions)
