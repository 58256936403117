import React from 'react'
import store from './store'

import { selectCircuitId } from './actions'

class SelectedCircuitIds extends React.Component {
  constructor() {
    super()
    this.unsubscribe = store.subscribe(this.stateChange.bind(this))
    this.state = {
      selectedCircuitIds: store.getState().selectedCircuitIds
    }
  }

  stateChange() {
    this.setState({
      selectedCircuitIds: store.getState().selectedCircuitIds
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  componentDidMount() {
    const { initiailCircuitIds } = this.props

    initiailCircuitIds.forEach(circuitId => store.dispatch(selectCircuitId(circuitId)))
  }

  render() {
    const { selectedCircuitIds } = this.state

    return(
      <>
        {selectedCircuitIds.map((circuitId, idx) => (
          <input
            key={idx}
            type={'hidden'}
            name={'circuit_ids[]'}
            value={circuitId}
          />))
        }
      </>
    )
  }
}

export default SelectedCircuitIds
