import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import ProgressBar from '@ramonak/react-progress-bar'
import { Uploading, Pending } from './helperComponents'
import LinkWithStatus from '../shared/navigation/LinkWithStatus'
import FileInput from './FileInput'
import DropdownActions from './DropdownActions'
import { importFile, generateWires, addSelected, fetchImportFile, setLoadingFile } from './slice'

const { Option } = components
const FileOption = (props) => (
  <Option {...props}>
    <div className='flex flex-col'>
      {props.data.id && <div>id: {props.data.id}</div>}
      <div>{props.data.label}</div>
      <div className='pt-1'>{props.data.importedBy}</div>
    </div>
  </Option>
)
const FileActions = ({ formRef, filesList, fileType, fileName, fileId }) => {
  const selected = useSelector(state => state.importers.selected[fileType]) || ''
  const dispatch = useDispatch()
  const downloadUrl = useSelector(state => state.importers.uploadUrl)
  const loadingFile = useSelector(state => {
    if (state.importers.loadingFile) {
      return state.importers.loadingFile[fileType]
    }
    return null
  })
  let fetchImportInterval = null

  const importVersions = [{ value: 'all', label: 'Current State' },
    ...filesList.sort((a, b) => new Date(b.importedAt) - new Date(a.importedAt))
      .map(f => ({ value: f, label: f.importedAt, id: f.id, importedBy: f.importedBy }))]

  const latestVersion = filesList.reduce((a, b) => a.date > b.date ? b : a)

  const buildFormData = (form, formData, attrs) => {
    const { name, files } = form.target
    formData.append('import_file[file]', files[0], { type: 'text/csv' })
    formData.append('import_file[import_type]', name)
    Object.entries(attrs).forEach(([key, val]) => {
      formData.append(`import_attrs[${key}]`, val)
    })
    return formData
  }

  const uploadFile = (evt, attrs = {}) => {
    evt.preventDefault()

    const form = formRef.current
    const formData = new FormData(form)
    buildFormData(evt, formData, attrs)

    dispatch(importFile({ url: form.action, formData }))
  }

  const disabledImports = ['connectorlist', 'devices', 'category_connector', 'componentalias']
  const labelForSelect = () => {
    if (selected === ''){
      return 'Select version'
    } else if (selected === 'all'){
      return 'Current State'
    } else {
      return `id: ${selected.id}:: ${selected.importedAt}`
    }
  }

  useEffect(() => {
    if (loadingFile && loadingFile.status && fetchImportInterval == null)
      fetchImportInterval = setInterval(() => { dispatch(fetchImportFile({ fileType, importFileId: loadingFile.id, actionType: loadingFile.actionType })) }, 1000)
    return () => clearInterval(fetchImportInterval)
  }, [loadingFile])

  useEffect(() => {
    if (loadingFile)
      return

    const runningFile = filesList.find(file => ['running', 'pending'].includes(file.status))
    runningFile && dispatch(setLoadingFile({
      fileType: runningFile.fileType,
      importFileId: runningFile.id,
      status: runningFile.status,
      processingProgress: runningFile.processingProgress
    }))
  }, [loadingFile])

  return (
    <>
      {loadingFile &&
        <div className='ml-2' style={{minWidth: '150px'}}>
          {loadingFile.status === 'running' ?
            <>
              <Uploading />
              <ProgressBar
                completed={loadingFile.processingProgress !== undefined ? loadingFile.processingProgress : 0}
                maxCompleted={100}
                bgColor='#0189E9'
              />
            </>
            : <Pending />
          }
        </div>
      }
      <div
        className={`relative flex flex-row justify-end items-center ${['connector_layout', 'device_connections'].includes(fileType) ? 'w-1/3' : 'w-1/3'} font-light ${loadingFile && 'hidden'}`}
      >
        {fileType === 'device_connections' &&
          <LinkWithStatus
            onClickFunc={() => dispatch(generateWires())}
            href={''}
            className='cursor-pointer inline-block text-blue-500 underline ml-3 mr-3'
            title='Generate Wires'
          />
        }
        { fileType === 'connector_layout' ?
          <>
            <span className="mr-4">
              <FileInput
                labelTitle={'Import Connector Side'}
                fileFieldName={fileType}
                fileFieldId={'connector-layout-connector-side'}
                onChange={(e) => uploadFile(e, {svg_side: 'connector'})}
              />
            </span>
            <FileInput
              labelTitle={'Import Terminal Side'}
              fileFieldName={fileType}
              fileFieldId={'connector-layout-terminal-side'}
              onChange={(e) => uploadFile(e, {svg_side: 'terminal'})}
            />
          </> :
          <FileInput
            labelTitle={'Import'}
            fileFieldName={fileType}
            fileFieldId={fileType}
            onChange={uploadFile}
            isDisabled={disabledImports.includes(fileType)}
          />
        }
        <div className='ml-2' style={{minWidth: '250px'}}>
          <Select
            inputName={'blank'}
            isMulti={false}
            value={{label: labelForSelect()}}
            options={importVersions}
            placeholder='Select version'
            className='w-full'
            onChange={(choice) => dispatch(addSelected({choice, fileType}))}
            components={{ Option: FileOption }}
          />
        </div>
        <DropdownActions
          fileType={fileType}
          downloadUrl={`${downloadUrl}/${fileName}`}
          latestVersion={latestVersion}
        />
      </div>
    </>
  )
}

FileActions.propTypes = {
  filesList: PropTypes.array,
  formRef: PropTypes.object.isRequired,
  fileType: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired
}

export default FileActions
