import React from 'react'
import PropTypes from 'prop-types'
import LinkWithStatus from '../shared/navigation/LinkWithStatus'
import DownloadPackageButton from './DownloadPackageButton'

const ActionPanel = ({searchValue, updateSearchValue, handleAction, generateAllWires}) => (
  <div className='flex flex-row w-full flex-grow'>
    <div className='w-1/2'>
      <label className='flex flex-col w-full space-y-2'>
        <div className='flex space-x-5'>
          <div className='w-2/3'>
            <input
              autoComplete='off'
              type='search'
              className='w-full bg-white h-10 px-5 pr-5 rounded focus:outline-none'
              placeholder="Enter connector's name"
              value={searchValue}
              onChange={(e) => updateSearchValue(e.target.value)}
            />
          </div>
          <div className='w-2/3'>
            <button className='btn' onClick={handleAction}>Search</button>
          </div>
        </div>
      </label>
    </div>
    <div className='w-1/2 text-right'>
      <DownloadPackageButton />
      <LinkWithStatus
        href='#'
        className='cursor-pointer inline-block bg-tracer-lt-blue font-light hover:bg-blue-700 text-white py-2 px-3 rounded'
        title='Generate All Wires'
        onClickFunc={generateAllWires}
      />
    </div>
  </div>
)

ActionPanel.propTypes = {
  searchValue: PropTypes.string.isRequired,
  updateSearchValue: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  generateAllWires: PropTypes.func.isRequired
}

export default ActionPanel
