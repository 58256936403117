import React from 'react'
import PropTypes from 'prop-types'
import Pagination from '../shared/navigation/Pagination'

// FIXME: it is not used anywhere, consider to remove it
class HarnessesTable extends React.Component{
  state = { searchQuery: this.props.searchQuery }

  setSearchQuery = (event) => {
    this.setState({ searchQuery: event.target.value })
  }

  render() {
    const { harnesses, searchPath, pagination } = this.props

    return (
      <div className='flex flex-col pt-6 flex-grow'>
        <form
          className='flex flex-col w-full space-y-2 pb-5'
          action={searchPath}
          acceptCharset='UTF-8'
          method='get'
        >
          <div className='flex space-x-5'>
            <div className='w-1/3'>
              <input
                autoComplete='off'
                className='w-full bg-white h-10 px-5 pr-5 rounded focus:outline-none'
                placeholder='Enter Harness Name'
                type='search'
                name='q[full_cont]'
                value={this.state.searchQuery || ''}
                onChange={e => this.setSearchQuery(e)}
              />
            </div>
            <div className='w-2/3'>
              <button className='btn'>Search</button>
            </div>
          </div>
        </form>
        <div className='flex flex-row bg-tracer-lt-blue rounded-t text-white px-3 text-sm font-light'>
          <div className='w-2/12 px-2'>
            Name
          </div>
          <div className='w-2/12 px-2'>
            Abbreviation
          </div>
          <div className='w-1/12 px-2'>
            Part #
          </div>
          <div className='w-1/12 px-2'>
            Base Part #
          </div>
          <div className='w-4/12 px-2'>
            Description
          </div>
          <div className='w-1/12 px-2'>
            Mappings
          </div>
          <div className='w-1/12 px-2'></div>
        </div>
        { harnesses.map(
            (harness, i) => (
              <div key={i} className='flex flex-row items-center bg-white py-2 px-3 border-b border-tracer-dark-blue'>
                <div className='w-2/12 px-2 break-all'>
                  {harness.name}
                </div>
                <div className='w-2/12 px-2 break-all'>
                  {harness.abbreviation}
                </div>
                <div className='w-1/12 px-2 break-all'>
                  {harness.partNumber}
                </div>
                <div className='w-1/12 px-2 break-all'>
                  {harness.basePartNumber}
                </div>
                <div className='w-4/12 px-2 break-all'>
                  {harness.description}
                </div>
                <div className='w-1/12 px-2 break-all'>
                  0
                </div>
                <div className='w-1/12 px-2 text-center'>
                  <a className='inline-block text-blue-500 underline' href={harness.editPath}>
                    Edit
                  </a>
                </div>
              </div>
            )
          )}
        <Pagination context={pagination} />
      </div>
    )
  }
}

HarnessesTable.propTypes = {
  searchQuery: PropTypes.string,
  searchPath: PropTypes.string,
  harnesses: PropTypes.array.isRequired,
  pagination: PropTypes.object
}

export default HarnessesTable
