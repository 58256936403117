import React from 'react'
import PropTypes from 'prop-types'

class TopHeader extends React.Component {
  state = {
    show: false
  }

  onToggle = () => {
    this.setState({ show: !this.state.show })
  }

  render() {
    const { dtcs } = this.props
    const { show } = this.state
    const dtcsSelected = dtcs.map(dtc => dtc.name).join(', ')

    return(
      <div className='relative w-full'>
        <div className='font-medium'>Mapping {dtcsSelected} for {this.props.vehicleSid}</div>
        <div className='underline cursor-pointer' onClick={this.onToggle}>{show ? 'Close details' : 'See details'}</div>
        { this.state.show &&
            <div className='absolute text-white bg-tracer-dark-blue rounded px-5 mt-6 -ml-6 w-full z-40'>
              <div className='max-h-1/2 overflow-y-scroll'>
                { dtcs.map(
                  dtc => <ul key={`dtc-${dtc.id}`} className='bg-tracer-dark-blue'>
                    <li key={dtc.id} className={`w-full py-4 ${(dtcs[dtcs.length - 1] !== dtc) && 'border-b border-gray-700'} text-white text-sm`}>
                      <div className='flex'>
                        <div className='w-1/6 font-bold'>{dtc.name}</div>
                        <div className='w-1/6 font-bold'>{dtc.acronym}</div>
                        <div className='w-4/6'>{dtc.description}</div>
                      </div>
                    </li>
                  </ul>
                )
              }
            </div>
          </div>
        }
      </div>
    )
  }
}

TopHeader.propTypes = {
  dtcs: PropTypes.array.isRequired,
  vehicleSid: PropTypes.string.isRequired
}

export default TopHeader
