import React from 'react'
import PropTypes from 'prop-types'
import GeneralTrixEditor from 'components/shared/TrixEditor'
import {
  errorStyle, labelStyle, labelClass, panelClass, rowClass, panelStyle
} from './miscHelpers'

const TrixEditor = ({
  errors, label, name, ...params
}) => (
  <div className={rowClass}>
    <input type='hidden' value={params.value} name={name} id={name} />
    <div className={labelClass} style={labelStyle}>{label}</div>
    <div className={panelClass}>
      <GeneralTrixEditor input={name} style={panelStyle} {...params} />
      {errors.map((message) => (<p style={errorStyle}>{message}</p>))}
    </div>
  </div>
)

TrixEditor.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  name: PropTypes.string
}

TrixEditor.defaultProps = {
  errors: [],
  label: '',
  name: ''
}

export default TrixEditor
