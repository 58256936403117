import React from 'react'
import PropTypes from 'prop-types'

import CheckBox from '../../shared/CheckBox'

const DtcRow = ({ dtcId, checked, triggerDtc }) =>
  <div className='inline-block'>
    <CheckBox
      name={`dtc_id[${dtcId}]`}
      value={dtcId}
      checked={checked}
      onChange={triggerDtc}
    />
  </div>

DtcRow.propTypes = {
  dtcId: PropTypes.number.isRequired
}

import { connect } from 'react-redux'
import { triggerDtc } from './tatMappingsSlice'

const mapStateToProps = (state, { dtcId }) => ({ checked: state.tatMappings.selectedDtcs.includes(dtcId) })
const mapDispatchToProps = (dispatch, { dtcId }) => ({ triggerDtc: () => dispatch(triggerDtc(dtcId)) })

export default connect(mapStateToProps, mapDispatchToProps)(DtcRow)
