import React from 'react'
import PropTypes from 'prop-types'
import DtcMappingRows from './DtcMappingRows'

const DtcMappingItem = (props) => {
  const {
    dtcName,
    dtcAcronym,
    destroyAllByDtcUrl,
    startConnectorMappings,
    endConnectorMappings,
    devices,
    circuitMappings
  } = props;

  return (
    <div className='bg-white w-full py-2 pl-1 border-b border-tracer-dark-blue'>
      <div className='flex flex-row'>
        <div className='w-12 font-bold text-sm'>DTC: </div>
          <div className='w-5/6'>
            <div className='group hover:bg-blue-100'>
              <div className='group flex justify-between'>
                <span>{dtcName}</span>
                <a
                  href={destroyAllByDtcUrl}
                  className='text-red-500 underline group-hover:visible invisible'
                  rel={'nofollow'}
                  data-method={'delete'}
                  data-confirm={'Are you sure?'}
                >
                  Clear Mappings
                </a>
              </div>
            </div>
          </div>
        </div>

      <div className='flex flex-row'>
        <div className='w-12 font-bold text-sm'>ECU: </div>
        <div>{dtcAcronym}</div>
      </div>
      <DtcMappingRows mappings={startConnectorMappings} category='Start' />
      <DtcMappingRows mappings={endConnectorMappings} category='End' />
      <DtcMappingRows mappings={devices} category='Devices' />
      <DtcMappingRows mappings={circuitMappings} category='Circuits' />
    </div>
  )
}

DtcMappingItem.propTypes = {
  dtcName: PropTypes.string.isRequired,
  dtcAcronym: PropTypes.string.isRequired,
  destroyAllByDtcUrl: PropTypes.string.isRequired,
  startConnectorMappings: PropTypes.array.isRequired,
  endConnectorMappings: PropTypes.array.isRequired,
}

export default DtcMappingItem
