import React from 'react'

import * as Routes from 'routes'

const getUrl = (ids, sid) => Routes.vehicleDtcMappingsPath(sid, { dtc_id: ids })

const EditMappings = ({ selectedDtcs, sid }) =>
  <a
    href={getUrl(selectedDtcs, sid)}
    onClick={(e) => (selectedDtcs.length < 1 ? e.preventDefault() : true)}
    className="btn"
  >
    Edit Mappings for {selectedDtcs.length} DTCs
  </a>

EditMappings.propTypes = {}

import { connect } from 'react-redux'

const mapStateToProps = ({ tatMappings: {selectedDtcs}, vehicle: {sid} }) => ({ selectedDtcs, sid })

export default connect(mapStateToProps)(EditMappings)
