export const rowClass = 'flex flex-row p-5'

export const labelClass = 'flex-grow-0 flex-shrink-0 flex-basis-'

export const panelClass = 'flex-grow pr-5'

export const errorStyle = {
  color: '#ff0000',
  fontWeight: 'bold'
}

export const labelStyle = {
  fontSize: '1.0em',
  fontWeight: 'bold',
  color: '#5E6469',
  'flex-basis': '20%'
}

// FIXME: try to remove
export const inputStyle = {
  width: 'calc(80% - 35px)'
}

export const panelStyle = {
  backgroundColor: 'white'
}

export const buttonStyle = {
  borderRadius: '200px',
  display: 'inline-block',
  fontWeight: 'bold',
  fontSize: '1.0em',
  lineHeight: '12px',
  marginRight: '3px',
  padding: '7px 16px 6px',
  textDecoration: 'none',
  backgroundColor: '#838a90',
  backgroundImage: 'linear-gradient(180deg, #838a90, #414549)',
  textShadow: '#000 0 1px 0',
  boxShadow: '0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 0 0px rgba(255, 255, 255, 0.2) inset',
  border: 'solid 1px #484e53',
  borderTopColor: '#616a71',
  borderRightColor: '#484e53',
  borderBottomColor: '#363b3f',
  borderLeftColor: '#484e53',
  color: '#efefef',
  cursor: 'pointer'
}
