import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LinkWithStatus from '../shared/navigation/LinkWithStatus'
import * as Routes from 'routes'

const DownloadPackageButton = ({
  sid,
  createExport,
  fetchExport,
  clearPowerDistributionExport,
  powerDistributionExport
}) => {
  let fetchExportInterval = null
  let buttonTitle
  if (powerDistributionExport == null)
    buttonTitle = 'Download Package'
  else if (powerDistributionExport.status === 'generating')
    buttonTitle = 'Generating Package...'
  else
    buttonTitle = 'Download Generated Package'

  useEffect(() => {
    if (powerDistributionExport && powerDistributionExport.status === 'generating' && fetchExportInterval == null)
      fetchExportInterval = setInterval(() => { fetchExport(powerDistributionExport.id) }, 1000)

    return () => clearInterval(fetchExportInterval)
  }, [powerDistributionExport])

  useEffect(() => {
    if (powerDistributionExport && powerDistributionExport.status === 'finished')
      clearInterval(fetchExportInterval)
  }, [powerDistributionExport])

  const onClick = () => {
    if (powerDistributionExport == null)
      createExport()
    else if (powerDistributionExport.status == 'finished') {
      window.location.href = Routes.downloadVehiclePowerDistributionExportPath(sid, powerDistributionExport.id)
      clearPowerDistributionExport()
    }
  }

  return (
    <LinkWithStatus
      className='cursor-pointer inline-block bg-tracer-lt-blue font-light hover:bg-blue-700 text-white py-2 px-3 mr-4 rounded'
      title={buttonTitle}
      href='#'
      onClickFunc={onClick}
    />
  );
};

import { connect } from 'react-redux'
import { createExport, fetchExport, clearPowerDistributionExport } from './slice'

const mapStateToProps = (state) => (
  {
    sid: state.vehicle.sid,
    powerDistributionExport: state.powerDistributions.powerDistributionExport
  }
)

const mapDispatchToProps = (dispatch) => ({
  createExport: () => dispatch(createExport()),
  fetchExport: (id) => dispatch(fetchExport(id)),
  clearPowerDistributionExport: () => dispatch(clearPowerDistributionExport())
})

export default connect(mapStateToProps, mapDispatchToProps)(DownloadPackageButton)
