import React from 'react'
import PropTypes from 'prop-types'
import CheckBox from './CheckBox'

const ItemRow = (props) => {
  const {
    inputName,
    name,
    value,
    onChange,
    description,
    isChecked
  } = props

  return (
    <div
      className='hover:bg-blue-100 flex flex-row items-center bg-white w-full px-4 py-2 border-b border-tracer-dark-blue cursor-pointer'
    >
      <div>
        <CheckBox
          name={inputName}
          value={value}
          onChange={onChange}
          checked={isChecked}
        />
      </div>
      <div
        className='w-full pl-4'
        onClick={onChange}
      >
        <div className='font-bold'>
          {name}
        </div>
        <div>
          {description}
        </div>
        <div>
          {props.children}
        </div>
      </div>
    </div>
  )
}

ItemRow.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func.isRequired,
  description: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  isChecked: PropTypes.bool
}

export default ItemRow
