import React from 'react'
import { CONNECTOR_TYPE } from "./actions"
import store from './store'

class SelectConnectorsText extends React.Component {
  constructor() {
    super()
    this.unsubscribe = store.subscribe(this.stateChange.bind(this))
    this.state = {
      connectorType: store.getState().selectedConnectorType
    }
  }

  stateChange() {
    this.setState({
      connectorType: store.getState().selectedConnectorType
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  render() {
    const { destinationOnly } = this.props
    const text = (this.state.connectorType === CONNECTOR_TYPE.end) ? 'Select Start Connectors' : 'Select End Connectors'

    return(
      <h1 className='text-white text-center font-medium text-xl'>
        {text}
      </h1>
    )
  }
}

export default SelectConnectorsText