import React from 'react'
import PropTypes from 'prop-types'
import SubmitButtonWithStatus from '../../shared/navigation/SubmitButtonWithStatus'

const Form = (props) => {
  const { authenticity_token, connectors, formUrl } = props

  return (
    <form
      action={formUrl}
      acceptCharset='UTF-8'
      className='w-full'
      method='post'
    >
      {
        connectors.map((connector, idx)=>(
          <div key={idx}>
            <input
              type='hidden'
              name={`connector_view[mappings[][connector_id]]`}
              value={connector.connector_id}
            />

            <input
              type='hidden'
              name={`connector_view[mappings[][svg_group_reference]]`}
              value={connector.reference}
            />

            <input
              type='hidden'
              name={`connector_view[mappings[][auto_mapped]]`}
              value={connector.auto_mapped ? true : false}
            />
          </div>
        ))
      }

      <input type='hidden' name='authenticity_token' value={authenticity_token} />
      <input type='hidden' name='_method' value='put' />
      <input
        type='submit'
        className='btn my-4 w-full'
        value='Save Mappings'
      />
    </form>
  )
}

Form.propTypes = {
  formUrl: PropTypes.string.isRequired,
  connectors: PropTypes.array.isRequired,
  authenticity_token: PropTypes.string.isRequired,
}

export default Form
