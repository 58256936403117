import React, { useState, useCallback } from 'react'

import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import * as Routes from 'routes'

const ConnectorViewRow = ({sid, connectorView, toggleMappingActions, showLoader, isOpen}) => (
  <div className='w-full'>
    <div className='flex flex-row items-center bg-white py-2 px-3 border-b border-tracer-dark-blue'>
      <div className='w-2/6 px-2 break-all'>
        <div className='font-medium'>
          {connectorView.name}
        </div>
        <div className='flex'>
          <div className='text-xs font-light mr-5'>
            {connectorView.harnessLevelCount}
            {' '}
            Level
            { connectorView.harnessLevelCount > 1 ? 's' : ''}
          </div>
          <div className='text-xs font-light'>
            {connectorView.harnessesCount}
            {' '}
            Harness
            { connectorView.harnessesCount > 1 ? 'es' : ''}
          </div>
        </div>
        <div className='text-xs font-light'>
          {`Created at: ${connectorView.createdAt}`}
        </div>
        <div className='text-xs font-light'>
          {`Updated at: ${connectorView.updatedAt}`}
        </div>
      </div>
      <div className='w-1/6 px-2 break-all'>
        <a
          className='inline-block text-blue-500 underline'
          href='javascript:void(null)'
          onClick={() => toggleMappingActions(connectorView)}
        >
          Actions
        </a>
      </div>
      <div className='w-1/6 px-2 break-all'>
        <a
          className='inline-block text-blue-500 underline'
          href={Routes.editVehicleConnectorViewPath(sid, connectorView.id)}
        >
          Edit View
        </a>
      </div>
      <div className='w-1/6 px-2 break-all'>
        <a
          className='inline-block text-blue-500 underline'
          href={Routes.editMappingsVehicleConnectorViewPath(sid, connectorView.id)}
          onClick={showLoader}
        >
          Edit Mappings
        </a>
      </div>
      <div className='w-1/6 px-2 break-all'>
        <a
          className='inline-block text-blue-500 underline'
          href={Routes.newVehicleConnectorViewPath(sid, { clone_from: connectorView.id })}
        >
          Clone View
        </a>
      </div>
      <div className='w-1/6 px-2 break-all'>
        <a
          href={Routes.vehicleConnectorViewPath(sid, connectorView.id)}
          className='inline-block text-red-500 underline'
          rel='nofollow'
          data-method='delete'
          data-confirm='Are you sure? You will delete all vehicle view mappings too!'
        >
          Delete View
        </a>
      </div>
    </div>
    <SlideDown className='flex flex-row items-center bg-white border-b py-2 px-3' closed={!isOpen}>
      <div className='flex flex-row items-center w-full' style={{ backgroundColor: '#F8FAFC' }}>
        <div className='w-2/6 px-2' style={{ minHeight: '200px' }}>
          <img src={connectorView.pngImageUrl} />
        </div>
        <div className='flex flex-col w-4/6 content-start' style={{ minHeight: '150px' }}>
          <div className='flex flex-wrap flex-grow'>
            <div className='w-1/3 px-2 hidden'>
              <a
                href='javascript:void(null)'
                className='inline-block text-blue-500 underline'
              >
                Export to Tracer Data repo
              </a>
            </div>
            <div className='w-1/3 px-2 hidden'>
              <a
                href='javascript:void(null)'
                className='inline-block text-blue-500 underline'
              >
                Test in Tracer
              </a>
            </div>
            <div className='w-1/3 px-2 hidden'>
              <a
                href='javascript:void(null)'
                className='inline-block text-blue-500 underline'
              >
                Sync in Tracer
              </a>
            </div>
            <div className='w-1/3 px-2'>
              <a
                href={Routes.downloadVehicleConnectorViewPath(sid, connectorView.id)}
                className='inline-block text-blue-500 underline'
                target='_blank'
                rel='noreferrer'
              >
                Download as ZIP
              </a>
            </div>
            <div className='w-1/3 px-2' />
            <div className='w-1/3 px-2' />
          </div>
        </div>
      </div>
    </SlideDown>
  </div>
)

export default ConnectorViewRow
