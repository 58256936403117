import React, { useCallback, useState } from 'react'
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

const DtcMemoRow = ({ memo, openedMemos, triggerMemo, changeMemoStatus, refreshMemo }) => {
  const hasValidDtc = !!memo.dtcCode

  return(
    <div className='bg-white border-b border-gray-500'>
      <div className={`flex px-3 pt-3 ${hasValidDtc ? 'pb-3' : ''}`}>
        <div className='w-1/5'>{ memo.ecuAcronym || memo.tracerMemo.ecuAcronym }</div>
        <div className='w-1/5'>{ memo.dtcCode || memo.tracerMemo.dtcCode }</div>
        <div className='w-3/4'>{ memo.shortDesc }</div>
        <div className='w-1/12'>
          <span
            className='inline-block text-blue-500 underline cursor-pointer'
            onClick={() => triggerMemo(memo.id)}
          >
            Review
          </span>
        </div>
      </div>
      { !hasValidDtc &&
        <div className='px-3 pb-3 text-xs text-red-600 font-bold'>
          You can't Approve this Memo, DTC not associated to the current Model, please add the DTC first.
        </div>
      }

      <SlideDown
        className='flex w-full border-b border-gray-200 px-3 py-2 items-start'
        style={{backgroundColor: '#F8FAFC'}} // TODO: use bg-stale-100 class after migration to newest Tailwind
        closed={!openedMemos.includes(memo.id)}
      >
        <div className='w-1/2 border-r border-gray-300'>
          <div className='border-b p-2 border-gray-300 font-medium'>
            Memo
          </div>
          <div className='p-2'>
            <div dangerouslySetInnerHTML={{__html: memo.memo}} />
          </div>
        </div>

        <div className="w-1/2 border-gray-300">
          <div className="flex justify-between border-b p-2 border-gray-300">
            <div className="w-1/4 font-medium">
              Tracer Memo
            </div>
            <div className="font-medium cursor-pointer">
              { hasValidDtc ?
                <span
                  className='accept-btn'
                  onClick={() => changeMemoStatus(memo.id, 'accept')}
                >
                  Accept
                </span>
                :
                <span
                  className='refresh-btn'
                  onClick={() => refreshMemo(memo.id)}
                >
                  Refresh
                </span>
              }
              <span
                className='reject-btn'
                onClick={() => changeMemoStatus(memo.id, 'reject')}
              >
                Reject
              </span>
            </div>
          </div>
          <div className="p-2 w-full">
            { memo.shortDesc !== memo.tracerMemo.shortDesc &&
              <div className='font-medium'>
                {memo.tracerMemo.shortDesc}
              </div>
            }

            <div dangerouslySetInnerHTML={{__html: memo.tracerMemo.memo}} />
          </div>
        </div>
      </SlideDown>
    </div>
  )
}

DtcMemoRow.propTypes = {}

import { connect } from 'react-redux'
import { changeMemoStatus, triggerMemo, refreshMemo } from './dtcMemosSlice'

const mapStateToProps = ({ dtcMemos: { openedMemos } }) => ({ openedMemos })

const mapDispatchToProps = (dispatch) => ({
  triggerMemo: (id) => dispatch(triggerMemo({ id })),
  refreshMemo: (id) => dispatch(refreshMemo({ id })),
  changeMemoStatus: (id, status) => dispatch(changeMemoStatus({ id, status }))
})

export default connect(mapStateToProps, mapDispatchToProps)(DtcMemoRow)
