import React, { useState, useEffect, createRef } from 'react'
import PropTypes from 'prop-types'
import LoadingSpinnerOverlay from '../LoadingSpinnerOverlay'
import swal from 'sweetalert2'

const SubmitButtonWithStatus = (props) => {
  const {
    className,
    style,
    text,
    confirmText,
    confirmTitle
  } = props

  const submitInput = createRef()

  const [active, activate] = useState(false)

  useEffect(() => {
    if (active) {
      submitForm()
    }
  }, [active])

  const onClick = (e) => {
    if (!active) {
      e.preventDefault()
      confirmText ? showConfirmationAlert() : onConfirm()
    }
  }

  const showConfirmationAlert = () => {
    swal
      .fire({
        title: (confirmTitle || 'Action confirmation'),
        text: confirmText,
        focusConfirm: false,
        cancelButtonText: 'Cancel',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: 'rgb(245, 80, 80)',
        allowOutsideClick: true
      })
    .then((result) => {
       if (result.value) {
         onConfirm()
       } else if (
         result.dismiss === swal.DismissReason.cancel
       ) {
          onClose()
       }
     })
  }

  const onConfirm = () => {
    activate(true)
  }

  const onClose = () => {
    swal.close()
  }

  const submitForm = () => {
    submitInput.current && submitInput.current.click()
  }

  return (
    <>
      <input
        type='submit'
        className={`cursor-pointer ${className}`}
        style={style}
        onClick={(e) => onClick(e)}
        ref={submitInput}
        value={text}
      />
      <LoadingSpinnerOverlay
        active={active}
      />
    </>
  )
}

SubmitButtonWithStatus.propTypes = {
  className: PropTypes.string,
  style: PropTypes.string,
  text: PropTypes.string.isRequired,
  confirmText: PropTypes.string,
  confirmTitle: PropTypes.string
}

export default SubmitButtonWithStatus
