import React from 'react'
import PropTypes from 'prop-types'

const ReferenceSearch = ({placeholder, value, onChange}) => (
  <input
    autoComplete='off'
    type='search'
    className='w-full bg-white h-10 px-5 pr-5 rounded focus:outline-none'
    placeholder={placeholder}
    value={value}
    onChange={onChange}
  />
)

ReferenceSearch.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

export default ReferenceSearch
