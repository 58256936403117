import React from 'react'
import PropTypes from 'prop-types'
import LinkWithStatus from '../shared/navigation/LinkWithStatus'
import Pagination from '../shared/navigation/Pagination'
import {ProgresSpinner} from '../shared/CommonHelpers'
import * as Routes from 'routes'

const BlockedVehicleRow = (props) => {
  const blockers = props.blockers
  const messages = {
    importer: 'Importing...',
    re_importer: 'Re-Importing',
    graph_reset: 'Rebuilding Connector Graph',
    wirelist_importer: 'Importing Wirelist',
    dtcs_importer: 'Importing DTCs',
    connectorlist_importer: 'Importing Connector List',
    modules_importer: 'Importing Modules',
    inlines_importer: 'Importing Inlines',
    category_connector: 'Importing Connector Categories',
    harness_description_importer: 'Importing Harness Descriptions',
    siglist_importer: 'Importing Circuit List',
    devices_importer: 'Importing Devices',
    device_connections_importer: 'Importing Device Connecitons'
  }

  const state = blockers.map(b => messages[b.blocker]).join('. ')

  return (
    <div className='w-2/3 font-light justify-end space-x-5 flex pr-5'>
      <ProgresSpinner caption={state} />
    </div>
  )
}

const VehicleRow = (props) => {
  const vehicle = props.vehicle
  const blockers = vehicle.blockedBy || []
  const vehicleIsBlocked = blockers.length > 0

  return (
    <div className='flex flex-row items-center bg-white w-full py-2 px-3 border-b border-tracer-dark-blue'>
      <div className='w-1/3'>
        <div className='font-medium'> {vehicle.name} </div>
        <div className='text-xs font-light'>
          Make Model ID: {vehicle.makeModelId} | Last import: {vehicle.lastImportedAt}
        </div>
      </div>

      { vehicleIsBlocked && <BlockedVehicleRow blockers={blockers}/> }

      { !vehicleIsBlocked && <div className='w-2/3 font-light text-right space-x-5'>
          <LinkWithStatus href={Routes.vehiclePowerDistributionsPath(vehicle.sid)} className='btn py-1 px-2' title='Power Distribution' />
          <LinkWithStatus href={Routes.vehicleImportersPath(vehicle.sid)} className='btn py-1 px-2' title='Importers' />
          <LinkWithStatus href={Routes.vehicleDtcsPath(vehicle.sid)} className='btn py-1 px-2' title='DTCs' />
          <LinkWithStatus href={Routes.vehicleConnectorViewsPath(vehicle.sid)} className='btn py-1 px-2' title='Vehicle Views' />
        </div>
      }
    </div>
  )
}

class VehiclesTable extends React.Component{
  state = { ...this.props }

  setSearchQuery = (event) => {
    this.setState({ searchQuery: event.target.value })
  }

  render() {
    const { vehicles, activityLogEnabled, pagination } = this.props

    return (
      <div className='flex flex-col pt-6 flex-grow'>
        <form
          className='flex flex-col w-full space-y-2 pb-5'
          action='/vehicles'
          acceptCharset='UTF-8'
          method='get'
        >
          <div className='flex space-x-5'>
            <div className='w-1/3'>
              <input
                autoComplete='off'
                className='w-full bg-white h-10 px-5 pr-5 rounded focus:outline-none'
                placeholder='Enter Vehicle Name'
                type='search'
                name='q[full_cont]'
                value={this.state.searchQuery || ''}
                onChange={e => this.setSearchQuery(e)}
              />
            </div>
            <div className='w-2/3'>
              <button className='bg-tracer-lt-blue font-light hover:bg-blue-700 text-white py-2 px-3 rounded'>
                Search
              </button>
            </div>
          </div>
        </form>
        <div className='flex flex-row bg-tracer-lt-blue rounded-t text-white px-3 text-sm font-light'>
          <div className='w-full'>
            Vehicle Name
          </div>
        </div>
        { vehicles.map(
          (vehicle, index) => (<VehicleRow vehicle={vehicle} key={index} />)
        )}
        <div className='py-6'>
          <Pagination context={pagination} />
        </div>
      </div>
    )
  }
}

VehiclesTable.propTypes = {
  vehicles: PropTypes.array.isRequired,
  activityLogEnabled: PropTypes.bool,
  searchQuery: PropTypes.string,
  pagination: PropTypes.object
}

export default VehiclesTable
