import React, { useCallback, useState, useEffect, createRef } from 'react'

import PropTypes from 'prop-types'
import Select from 'react-select'
import CreateDtcMappingsCheckbox from '../dtc_mappings/CreateDtcMappingsCheckbox'

const CloneMappings = ({className, targetDtcs}) => {
  const [selectedTargetDtcs, setSelectedTargetDtcs] = useState([])

  const onChange = useCallback((dtcs) => {
    setSelectedTargetDtcs(dtcs)
  }, [selectedTargetDtcs, setSelectedTargetDtcs])

  return (
    <>
      <Select
        name='target_dtcs_ids[]'
        classNamePrefix='dtcs-filter'
        isMulti={true}
        options={targetDtcs}
        placeholder='Select DTCs'
        className={`cursor-pointer ${className}`}
        onChange={onChange}
      />
      {selectedTargetDtcs.length > 0 &&
        <>
          <CreateDtcMappingsCheckbox
            title='Clone existing DTC Mappings'
            field='create_dtc_connectors'
          />
          <CreateDtcMappingsCheckbox
            title='With Circuit Filters'
            field='create_dtc_circuits'
          />
        </>
      }
    </>
  )
}

CloneMappings.propTypes = {
  className: PropTypes.string,
  targetDtcs: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default CloneMappings
