import React, { createRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import FileActions from './FileActions'
import LinkWithStatus from '../shared/navigation/LinkWithStatus'

const FilesForm = ({ authToken }) => {
  const formRef = createRef()
  const uploadUrl = useSelector(state => state.importers.uploadUrl)
  const importFiles = useSelector(state => state.importers.importFiles)
  const uniqFilesByName = [...new Map(importFiles.map(file => [file.name, file])).values()]
  const filesByType = (fileType) => {
    return importFiles.filter((file) => file.fileType === fileType)
  }

  return (
    <div className='flex flex-col pt-6 flex-grow'>
      <div className='flex flex-row bg-tracer-lt-blue rounded-t text-white px-3 text-sm font-light'>
        <div className='w-full'>
          Importer
        </div>
      </div>
      <div className='flex flex-row items-center justify-between h-16 bg-white w-full py-2 px-3 border-b border-gray-300'>
        <div className='w-1/4'>
          <div className='font-bold flex-none'>
            dtc_memos.csv
          </div>
          <div className='text-sm' />
        </div>
        <LinkWithStatus
          onClickFunc={() => window.location.href = `${uploadUrl}/dtc_memos.csv`}
          href={''}
          className='cursor-pointer inline-block text-blue-500 underline mx-3'
          title='Download'
        />
      </div>
      <form
        action={uploadUrl}
        acceptCharset='UTF-8'
        method='post'
        encType='multipart/form-data'
        ref={formRef}
      >
        <input type='hidden' name='authenticity_token' value={authToken} />
        {uniqFilesByName.map(
          (file, index) => (
            <div key={index} className='flex flex-row items-center h-16 bg-white w-full py-2 px-3 border-b border-gray-300'>
              <div className='w-1/4'>
                <div className={`font-bold flex-none ${file.dependencies && 'text-gray-500'}`}>
                  {file.name}
                </div>
              </div>
              <div className='flex-grow'>
                <div className={`text-gray-500 text-sm text-center lg:text-left ${!file.dependencies && 'hidden'}`}>{file.dependencies}</div>
              </div>
              {!file.dependencies && (
                <FileActions
                  fileId={file.id}
                  filesList={filesByType(file.fileType)}
                  fileType={file.fileType}
                  fileName={file.name}
                  formRef={formRef}
                />
              )}
            </div>
          )
        )}
      </form>
      <div className='flex flex-row items-center justify-between h-16 bg-white w-full py-2 px-3 border-b border-gray-300'>
        <div className='w-1/4'>
          <div className='font-bold flex-none'>
            module_devices.csv
          </div>
          <div className='text-sm'/>
        </div>
        <LinkWithStatus
          onClickFunc={() => window.location.href = `${uploadUrl}/module_devices.csv`}
          href={''}
          className='cursor-pointer inline-block text-blue-500 underline mx-3'
          title='Download'
        />
      </div>
      <div className='flex flex-row items-center justify-between h-16 bg-white w-full py-2 px-3 border-b border-gray-300'>
        <div className='w-1/4'>
          <div className='font-bold flex-none'>
            meta.json
          </div>
          <div className='text-sm'/>
        </div>
        <LinkWithStatus
          onClickFunc={() => window.location.href = `${uploadUrl}/meta.json`}
          href={''}
          className='cursor-pointer inline-block text-blue-500 underline mx-3'
          title='Download'
        />
      </div>
    </div>
  )
}

FilesForm.propTypes = {
  authToken: PropTypes.string.isRequired
}

export default FilesForm
