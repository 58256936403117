import React from 'react'
import PropTypes from 'prop-types'

class PrettyPrintJson extends React.Component {
  render() {
       const { data } = this.props;
       return (<div><pre>{JSON.stringify(data, null, 2) }</pre></div>);
  }
}

PrettyPrintJson.propTypes = {
  data: PropTypes.string
}

export default PrettyPrintJson
