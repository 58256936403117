export const SELECT_PIN = 'SELECT_PIN'
export const DESELECT_PIN = 'DESELECT_PIN'

export const selectPin = (pin) => {
  return { type: SELECT_PIN, pin }
}
export const deselectPin = (pin) => {
  return { type: DESELECT_PIN, pin }
}


export const SELECT_START_CONNECTOR = 'SELECT_START_CONNECTOR';
export const SELECT_END_CONNECTOR = 'SELECT_END_CONNECTOR';
export const CONNECTOR_TYPE = {
  start: 'start',
  end: 'end'
};

export const selectStartConnector = () => {
  return { type: SELECT_START_CONNECTOR, connectorType: CONNECTOR_TYPE.start};
}
export const selectEndConnector = () => {
  return { type: SELECT_END_CONNECTOR, connectorType: CONNECTOR_TYPE.end};
}


export const SELECT_RELATED_CONNECTOR = 'SELECT_RELATED_CONNECTOR';
export const DESELECT_RELATED_CONNECTOR = 'DESELECT_RELATED_CONNECTOR';

export const selectRelatedConnector = (connectorId) => {
  return { type: SELECT_RELATED_CONNECTOR, connectorId }
}
export const deselectRelatedConnector = (connectorId) => {
  return { type: DESELECT_RELATED_CONNECTOR, connectorId }
}


export const SELECT_CIRCUIT_ID = 'SELECT_CIRCUIT_ID';
export const DESELECT_CIRCUIT_ID = 'DESELECT_CIRCUIT_ID';

export const selectCircuitId = (circuitId) => {
  return { type: SELECT_CIRCUIT_ID, circuitId }
}
export const deselectCircuitId = (circuitId) => {
  return { type: DESELECT_CIRCUIT_ID, circuitId }
}


export const SELECT_DB_LEVEL = 'SELECT_DB_LEVEL';
export const SELECT_CODE_LEVEL = 'SELECT_CODE_LEVEL';
export const FILTER_CONNECTORS_QUERY_TYPE = {
  dbLevel: 'db_level',
  codeLevel: 'code_level'
};

export const selectDbLevel = () => {
  return { type: SELECT_DB_LEVEL, queryType: FILTER_CONNECTORS_QUERY_TYPE.dbLevel};
}
export const selectCodeLevel = () => {
  return { type: SELECT_CODE_LEVEL, queryType: FILTER_CONNECTORS_QUERY_TYPE.codeLevel};
}

export const SELECT_SEARCH_OLD_LEVELS = 'SELECT_SEARCH_OLD_LEVELS';

export const selectSearchOldLevels = (value) => {
  return { type: SELECT_SEARCH_OLD_LEVELS, value: value};
}
