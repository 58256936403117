import React from 'react'
import PropTypes from 'prop-types'

class OpenNewBrowserTab extends React.Component {
  componentDidMount = () => {
    const win = window.open(this.props.url, '_blank');
    if (win != null) {
      win.focus();
    }
  }

  render() {
    return (null)
  }
}

OpenNewBrowserTab.propTypes = {
  url: PropTypes.string.required
}

export default OpenNewBrowserTab
