import React, { useEffect } from 'react'

import ActionPanel from './ActionPanel'
import DtcMemosTable from './DtcMemosTable'
import Pagination from '../../shared/navigation/Pagination'
import LoadingSpinnerOverlay from '../../shared/LoadingSpinnerOverlay'

const DtcMemos = ({ decrementPage, incrementPage, fetchDtcMemos, pagination, status }) => {
  // TODO: find better solution for this, we should trigger this update from reducer
  useEffect(() => { if (status === 'idle') fetchDtcMemos() }, [status, fetchDtcMemos])

  return (
    <div className='flex flex-col py-3 space-y-6'>
      <ActionPanel />

      <DtcMemosTable />

      <Pagination onClickNext={incrementPage} onClickPrev={decrementPage} context={pagination} />

      {/*TODO: this should be moved on upper level and shouldn't be duplicated in each tab*/}
      <LoadingSpinnerOverlay active={status === 'loading'} />
    </div>
  )
}

DtcMemos.propTypes = {}

import { connect } from 'react-redux'
import { incrementPage, decrementPage, fetchDtcMemos } from './dtcMemosSlice'

const mapStateToProps = ({ dtcMemos: { pagination, status } }) => ({ pagination, status })

const mapDispatchToProps = (dispatch) => ({
  fetchDtcMemos: () => dispatch(fetchDtcMemos()),
  incrementPage: () => dispatch(incrementPage()),
  decrementPage: () => dispatch(decrementPage()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DtcMemos)
