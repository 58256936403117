import React from 'react'
import PropTypes from 'prop-types'
import 'react-slidedown/lib/slidedown.css'
import axios from 'axios'
import flashStore from '../shared/alerts/state/store'
import { addFlashes } from '../shared/alerts/state/actions'
import { XCircle } from '@styled-icons/boxicons-solid'
import HarnessesSearchTable from './HarnessesSearchTable'
import HarnessLevelsSearchTable from './HarnessLevelsSearchTable'
import LoadingSpinnerOverlay from '../shared/LoadingSpinnerOverlay'
import { handleEnterKey, SELECT_ALL, DESELECT_ALL } from './utils'
import * as Routes from 'routes'

class ConnectorViewForm extends React.Component {
  initSelectedLevels = () => {
    const { connectorView, selectedLevelsIds } = this.props
    return selectedLevelsIds || connectorView?.harnessLevelsIds || []
  }

  initSelectedHarnesses = () => {
    const { connectorView, harnesses } = this.props
    return harnesses || connectorView?.harnesses || []
  }

  connectorViewAttr = (attr) => {
    const { connectorView } = this.props

    return connectorView && connectorView[attr]
  }

  state = {
    selectedHarnesses: this.initSelectedHarnesses(),
    openedHarnesses: [],
    connectorViewName: (this.connectorViewAttr('name') || ''),
    checkedHarnessLevels: this.initSelectedLevels(),
    loading: false,
    svgName: this.connectorViewAttr('svgImageName'),
    pngName: this.connectorViewAttr('pngImageName'),
    searchHarnessTableComponents: false
  }

  hlCheckHandler = (harnessLevelIds, action) => {
    const { checkedHarnessLevels } = this.state
    const selectedHarnessLevels = new Set(checkedHarnessLevels || [])

    harnessLevelIds.forEach(hl => {
      if ((selectedHarnessLevels.has(hl) && action !== SELECT_ALL) || action === DESELECT_ALL) {
        selectedHarnessLevels.delete(hl)
      } else {
        selectedHarnessLevels.add(hl)
      }
    })

    this.setState({ checkedHarnessLevels: Array.from(selectedHarnessLevels) })
  }

  connectorViewNameChange = (event) => {
    this.setState({ connectorViewName: event.target.value })
  }

  selectHarness = (harness) => {
    this.setState({
      selectedHarnesses: [...this.state.selectedHarnesses, harness],
      openedHarnesses: [...this.state.openedHarnesses, harness.id],
      searchHarnessTableComponents: false
    })
  }

  clearHarness = (harness, harnessLevels) => {
    const harnesses = this.state.selectedHarnesses
    const index = harnesses.indexOf(harness)
    if (index !== -1) {
      harnesses.splice(index, 1)
    }
    const harnessLevelsIds = harnessLevels.map(hl => hl.id)
    const afterClear = this.state.checkedHarnessLevels.filter(sl => !harnessLevelsIds.includes(sl))
    this.setState({ selectedHarnesses: harnesses, checkedHarnessLevels: afterClear })
  }

  renderHarnessSearchTable = () => {
    this.setState({searchHarnessTableComponents: true, openedHarnesses: []})
  }

  hideHarnessSearchTable = () => {
    this.setState({searchHarnessTableComponents: false})
  }

  toggleHarnesses = (harness) => {
    const openedHarnesses = this.state.openedHarnesses
    if (openedHarnesses.includes(harness.id)) {
      this.setState({ openedHarnesses: openedHarnesses.filter(h => h !== harness.id) })
    } else {
      this.setState({ openedHarnesses: [...openedHarnesses, harness.id] })
    }
  }

  harnessIsOpened = (h) => {
    return this.state.openedHarnesses.includes(h.id)
  }

  fileChanged = (e, type) => {
    const input = e.target

    if (input.value) {
      this.setState({ [`${type}Name`]: input.files[0].name })
    }
  }

  clearFile = (type) => {
    this.setState({ [`${type}Name`]: null })
  }

  appendFile = (form, formData, type) => {
    const file = this.state[`${type}Name`] && form.querySelector(`input[name='connector_view[${type}]']`).files[0]
    if (file) {
      formData.append(`connector_view[${type}]`, file)
    } else {
      formData.delete(`connector_view[${type}]`)
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    this.setState({ loading: true })

    const form = e.target
    const formData = new FormData(form)
    const types = ['png', 'svg']

    types.forEach(t => this.appendFile(form, formData, t))

    axios.post(e.target.action, formData,
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    ).then(response => {
      const data = response.data

      if (data.error) {
        this.handleError(data)
      } else if (data.success) {
        this.handleSuccess(data)
      }

      window.scrollTo(0, 0)
      this.setState({ loading: false })
    })
  }

  handleError = (data) => {
    flashStore.dispatch(
      addFlashes(
        [{
          body: data.error,
          type: 'error'
        }]
      )
    )
  }

  handleSuccess = (data) => {
    const { vehicle } = this.props

    flashStore.dispatch(
      addFlashes(
        [{
          body: data.success,
          type: 'success'
        }]
      )
    )

    if (data.created) {
      setTimeout(
        () => {
          window.location.href = Routes.editMappingsVehicleConnectorViewPath(vehicle.id, data.connector_view.id)
        }, 5000
      )
    }
  }

  render() {
    const {
      connectorView,
      vehicle,
      persisted,
      authenticity_token,
      clone_from
    } = this.props

    const {
      searchHarnessTableComponents,
      selectedHarnesses,
      connectorViewName,
      checkedHarnessLevels,
      svgName,
      pngName,
      loading
    } = this.state

    return (
      <div className='flex items-stretch flex-grow'>
        <LoadingSpinnerOverlay active={loading} />
        <div className='flex-1 w-1/2 flex flex-col bg-tracer-darker-blue pt-6 flex-grow'>
          { (checkedHarnessLevels.length > 0 || selectedHarnesses.length > 0)
            ? selectedHarnesses.map((selectedHarness) => (
              <HarnessLevelsSearchTable
                key={selectedHarness.id}
                harness={selectedHarness}
                checkedHarnessLevels={checkedHarnessLevels}
                onSelectHarnessLevel={(id, action) => this.hlCheckHandler(id, action)}
                onClearHarness={this.clearHarness}
                toggleHarnesses={this.toggleHarnesses}
                harnessIsOpened={this.harnessIsOpened}
                vehicle={vehicle}
              />
            ))
            : (
              <HarnessesSearchTable
                selectedHarnessesIds={selectedHarnesses.map((sh) => sh.id)}
                onSelectHarness={this.selectHarness}
                vehicle={vehicle}
              />
            )}

          {searchHarnessTableComponents && (
            <div className='flex flex-col'>
              <HarnessesSearchTable
                selectedHarnessesIds={selectedHarnesses.map((sh) => sh.id)}
                onSelectHarness={this.selectHarness}
                vehicle={vehicle}
              />
              <button
                onClick={this.hideHarnessSearchTable}
                className='btn my-8 mx-4'
              >
                Hide Harness Search Table
              </button>
            </div>
          )}
          {(searchHarnessTableComponents === false && selectedHarnesses.length > 0) &&
            <button
              onClick={this.renderHarnessSearchTable}
              className='btn my-8 mx-4'
            >
              Add Harness
            </button>
          }
        </div>
        <form
          className='flex-1 w-1/2 flex flex-col p-4 pt-6 bg-tracer-dark-blue text-white flex-grow'
          action={
            persisted ?
              Routes.vehicleConnectorViewPath(vehicle.sid, connectorView.id) :
              Routes.vehicleConnectorViewsPath(vehicle.sid)
          }
          acceptCharset='UTF-8'
          method='post'
          onSubmit={this.onSubmit}
          encType='multipart/form-data'
        >
          <input type='hidden' name='authenticity_token' value={authenticity_token} />
          <input type='hidden' name='clone_from' value={clone_from} />
          { persisted && <input name='_method' type='hidden' value='put' /> }
          { persisted && <input name='connector_view[id]' type='hidden' value={connectorView.id} /> }
          { selectedHarnesses.map((selectedHarness, i) => (
            <input
              key={i}
              type='hidden'
              name='connector_view[harness_ids][]'
              value={selectedHarness.id}
            />
          ))}
          { checkedHarnessLevels.map((hl, i) => (
            <input
              key={i}
              type='hidden'
              name='connector_view[harness_levels_ids][]'
              value={hl}
            />
          ))}
          <div className='flex'>
            Vehicle View Name
          </div>
          <div className='flex mt-6'>
            <input
              autoComplete='off'
              name={'connector_view[name]'}
              className='w-full bg-white  text-black h-10 px-5 pr-5 rounded focus:outline-none'
              placeholder='Front View'
              onChange={this.connectorViewNameChange}
              value={connectorViewName || ''}
              onKeyDown={handleEnterKey}
            />
          </div>

          { persisted &&
            <div>
              <div className='flex mt-6'>
                Current Image
              </div>

              <img src={connectorView.pngImageUrl} />
            </div>
          }

          <div className='flex mt-6'>
            Images
          </div>

          { persisted &&
            <div>
              <a
                href={connectorView.downloadZipPath}
                className={'inline-block text-blue-500 underline'}
                target={'_blank'}
              >
                Download persisted as ZIP
              </a>
            </div>
          }

          <div className='flex flex-col w-full mt-6'>
            <label htmlFor='svg_attachment'>SVG</label>
            <input
              name='connector_view[svg]'
              type='file'
              id='svg_attachment'
              onChange={(e) => this.fileChanged(e, 'svg')}
              style={{ display: svgName ? 'none' : 'inline' }}
            />
            { svgName &&
              <div>
                {!clone_from &&
                  <XCircle
                    className={'w-4 text-red-500 mr-3'}
                    onClick={e => this.clearFile('svg')}
                  />
                }
                {svgName}
              </div>
            }
            { persisted &&
              <div className='text-red-600 text-sm'>
                Updating the SVG image will erase all existing mappings!
              </div>
            }
            <label htmlFor='png_attachment'>PNG</label>
            <input
              name='connector_view[png]'
              type='file'
              id='png_attachment'
              onChange={e => this.fileChanged(e, 'png')}
              style={{ display: pngName ? 'none' : 'inline' }}
            />
            { pngName &&
              <div>
                {!clone_from &&
                  <XCircle
                    className={'w-4 text-red-500 mr-3'}
                    onClick={e => this.clearFile('png')}
                  />
                }
                {pngName}
              </div>
            }
          </div>
          <div className='flex mt-20'>
            <button type='submit' className='btn my-4 w-full'>
              {persisted ? 'Save Vehicle View Changes' : 'Add Vehicle View'}
            </button>
          </div>
        </form>
      </div>
    )
  }
}

ConnectorViewForm.propTypes = {
  connectorView: PropTypes.object.isRequired,
  vehicle: PropTypes.object.isRequired,
  harnesses: PropTypes.array,
  harnessLevelsIds: PropTypes.array,
  selectedLevelsIds: PropTypes.array,
  persisted: PropTypes.bool,
  authenticity_token: PropTypes.string.isRequired
}

export default ConnectorViewForm
