import React from 'react'
import PropTypes from 'prop-types'
import 'react-slidedown/lib/slidedown.css'
import Select from 'react-select'
import { SlideDown } from 'react-slidedown'
import axios from 'axios'
import ItemRow from '../shared/ItemRow'
import { SELECT_ALL, DESELECT_ALL, LoadingLevelsSpinner } from './utils'

class HarnessLevelsSearchTable extends React.Component{
  state = {
    searchLevelNameQuery: null,
    selectedOptionCode: null,
    openedConnectors: [],
    harnessLevels: [],
    optionCodes: [],
    loadingLevels: false
  }

  componentDidMount = () => {
    this.fetchHarnessesLevels()
  }

  toggleSpinner = () => {
    const loadingLevels = this.state.loadingLevels ? false : true
    this.setState({ ...(this.state), loadingLevels})
  }

  fetchHarnessesLevels = () => {
    const { vehicle, harness } = this.props
    const { searchLevelNameQuery, selectedOptionCode } = this.state

    const optionCodeParam = selectedOptionCode && selectedOptionCode.value

    this.toggleSpinner()
    axios.get(`/vehicles/${vehicle.sid}/harnesses/${harness.id}/harness_levels.json`, {
      params: {
        'q[full_cont]': searchLevelNameQuery,
        'option_code_ids': optionCodeParam && [optionCodeParam],
      }
    })
    .then((response) => {
      const data = response.data
      if (data.error) {
        alert(data.error.message)
        if (data.error.vehicle_is_blocked) {
          window.location = `/vehicles`
        }
      } else {
        this.setState(
          {
            ...this.state,
            harnessLevels: data.levels,
            optionCodes: [
              { label: 'Any', value: null },
              ...data.optionCodes.map(o => ({ value: o.id, label: o.name }))
            ]
          }
        )
        this.toggleSpinner()
      }
    })
    .catch((response) => this.toggleSpinner())
  }

  setSearchQuery = (event) => {
    this.setState({ searchLevelNameQuery: event.target.value })
  }

  selectOptionCode = (option) => {
    this.setState(
      { selectedOptionCode: option },
      () => { this.fetchHarnessesLevels() }
    )
  }

  toggleHarnessesLevels = (selection) => {
    this.props.onSelectHarnessLevel(
      this.state.harnessLevels.map((hl) => hl.id),
      selection
    )
  }

  toggleConnectors = (e, hl) => {
    e.stopPropagation()

    var openedConnectors = this.state.openedConnectors
    if (openedConnectors.includes(hl.id)) {
      openedConnectors = openedConnectors.filter(m => m !== hl.id)
    } else {
      openedConnectors.push(hl.id)
    }
    this.setState({ openedConnectors: openedConnectors })
  }

  connectorIsOpened = (hl) => {
    return this.state.openedConnectors.includes(hl.id)
  }

  render() {
    const {
      harness,
      onSelectHarnessLevel,
      onClearHarness,
      toggleHarnesses,
      harnessIsOpened,
      checkedHarnessLevels
    } = this.props

    const {
      harnessLevels,
      searchLevelNameQuery,
      selectedOptionCode,
      optionCodes,
      loadingLevels,
    } = this.state

    return (
      <div className='flex items-stretch'>
        <div className='flex-1 w-1/2 flex flex-col bg-tracer-darker-blue px-4 pb-4 flex-grow'>
          <div className='text-white mb-6'>
            <div className='flex'>
              <div className='text-white w-4/5'>
                <div>
                  Harness
                </div>
                <div className='font-bold'>
                  {harness.name || harness.harness_id}
                </div>
              </div>
              <span
                onClick={() => onClearHarness(harness, harnessLevels)}
                className='cursor-pointer my-4 underline text-white text-center w-1/5'
              >
                Clear
              </span>
            </div>
            <div className='flex'>
              <span
                onClick={() => toggleHarnesses(harness)}
                className='cursor-pointer underline text-white'
              >
                Select Harness Levels
              </span>
            </div>
          </div>
          <SlideDown closed={!harnessIsOpened(harness)}>
            <div className='w-full'>
              <form
                className='flex flex-col w-full space-y-2 pb-5'
                action={'searchPath'}
                acceptCharset='UTF-8'
                method='get'
              >
                <div className='flex space-x-5'>
                  <div className='w-2/3'>
                    <input
                      autoComplete='off'
                      className='w-full bg-white h-10 px-5 pr-5 rounded focus:outline-none'
                      placeholder='Enter Level Name'
                      type='search'
                      name='q[full_cont]'
                      value={searchLevelNameQuery || ''}
                      onChange={this.setSearchQuery}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          this.fetchHarnessesLevels()
                        }
                      }}
                    />
                  </div>
                  <div className='w-1/3'>
                    <Select
                      name={'q[acronym_cont]'}
                      inputName={'blank'}
                      classNamePrefix='dtcs-filter'
                      isMulti={false}
                      value={selectedOptionCode}
                      options={optionCodes}
                      placeholder={'Code'}
                      className='w-full'
                      onChange={this.selectOptionCode}
                    />
                  </div>
                </div>
              </form>
              <button
                onClick={() => this.toggleHarnessesLevels(SELECT_ALL)}
                className='btn my-2'
              >
                Select All
              </button>
              <button
                onClick={() => this.toggleHarnessesLevels(DESELECT_ALL)}
                className='btn my-2 ml-3'
              >
                Deselect All
              </button>
            </div>
            <div className='flex-auto overflow-auto scrollbar-height'>
              { loadingLevels && <LoadingLevelsSpinner />}
              { harnessLevels.map((hl, i) => (
                <div key={i} className='flex flex-row items-center bg-white w-full'>
                  <ItemRow
                    name={hl.name}
                    inputName={'harness_level_ids[]'}
                    value={hl.id}
                    onChange={() => onSelectHarnessLevel([hl.id])}
                    isChecked={checkedHarnessLevels.includes(hl.id)}
                    description={''}
                  >
                    { hl.connector_names.length > 0 &&
                      <div className={'text-sm max-h-5 overflow-hidden'}>
                        <span
                          onClick={e => this.toggleConnectors(e, hl)}
                          className={'text-blue-500 underline'}
                        >
                          View Connectors
                        </span>
                        <SlideDown
                          closed={!this.connectorIsOpened(hl)}
                          className={'pt-2 pb-1 max-h-1 overflow-y-auto'}
                          style={{ maxHeight: '350px' }}
                        >
                          {hl.connector_names.map(n => <p>{n}</p>)}
                        </SlideDown>
                      </div>
                    }
                  </ItemRow>
                </div>
              ))}
            </div>
          </SlideDown>
        </div>
      </div>
    )
  }
}

HarnessLevelsSearchTable.propTypes = {
  searchLevelNameQuery: PropTypes.string,
  harness: PropTypes.object.isRequired,
  vehicle: PropTypes.object.isRequired,
  onClearHarness: PropTypes.func.isRequired,
  onSelectHarnessLevel: PropTypes.func.isRequired,
  checkedHarnessLevels: PropTypes.array.isRequired,
}

export default HarnessLevelsSearchTable
