export const INIT_CONNECTORS = 'INIT_CONNECTORS'
export const initConnectors = (connectors) => {
  return { type: INIT_CONNECTORS, connectors }
}

export const SELECT_CONNECTOR = 'SELECT_CONNECTOR'
export const selectConnector = (connector) => {
  return { type: SELECT_CONNECTOR, connector }
}

export const MAP_CONNECTOR = 'MAP_CONNECTOR'
export const mapConnector = (connector, reference) => {
  return { type: MAP_CONNECTOR, connector, reference }
}

export const CLEAR_SELECTED_CONNECTOR = 'CLEAR_SELECTED_CONNECTOR'
export const clearSelectedConnector = () => {
  return { type: CLEAR_SELECTED_CONNECTOR }
}

export const SELECT_REFERENCE = 'SELECT_REFERENCE'
export const selectReference = (reference) => {
  return { type: SELECT_REFERENCE, reference }
}

export const CLEAR_SELECTED_REFERENCE = 'CLEAR_SELECTED_REFERENCE'
export const clearSelectedReference = () => {
  return { type: CLEAR_SELECTED_REFERENCE }
}

export const INIT_SVG = 'INIT_SVG'
export const initSvg = (svg) => {
  return { type: INIT_SVG, svg }
}

export const UPDATE_SVG = 'UPDATE_SVG'
export const updateSvg = (svg) => {
  return { type: UPDATE_SVG, svg }
}

export const INIT_REFERENCES = 'INIT_REFERENCES'
export const initReferences = (references) => {
  return { type: INIT_REFERENCES, references }
}

export const UPDATE_REFERENCES = 'UPDATE_REFERENCES'
export const updateReferences = (references) => {
  return { type: UPDATE_REFERENCES, references }
}
