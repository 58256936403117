import React from 'react'
import PropTypes from 'prop-types'
import RadioButton from '../../shared/RadioButton'
import store from '../state/store'
import { selectReference } from  '../state/actions'
import DuplicateSvgGroup from './DuplicateSvgGroup'

const ReferenceItem = ({reference, selected, sid, connectorViewId, authenticity_token}) => {
  const onChange = evt => {
    store.dispatch(selectReference(reference))
  }
  return (
    <div
      className='flex flex-col flex-grow items-center bg-white w-full px-2 py-3 border-b border-tracer-dark-blue cursor-pointer group hover:bg-blue-100'
      id={`ref-${reference}`}
    >
      <div
        className='flex flex-row flex-grow items-center w-full cursor-pointer group'
      >
        <div>
          <RadioButton
            name={reference}
            value={reference}
            onChange={onChange}
            checked={selected}
          />
        </div>
        <div
          className='w-full pl-2'
          onClick={onChange}
        >
          <div className='flex w-full font-bold group p-2 break-all text-sm'>
            {reference}
          </div>
        </div>
      </div>
      <DuplicateSvgGroup
        sid={sid}
        connectorViewId={connectorViewId}
        reference={reference}
        authenticity_token={authenticity_token}
      />
    </div>
  )
}

ReferenceItem.propTypes = {
  reference: PropTypes.string.isRequired,
  selected: PropTypes.bool
}

export default ReferenceItem
