import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import { Edit } from '@styled-icons/boxicons-solid'

import DtcRow from './DtcRow'
import DtcMappings from './DtcMappings'

const humanizeBool = (bool) => (bool ? 'Yes' : 'No')

const DtcRowExtended = ({ id, name, description, acronym, active, destinationOnly, mappings, vehicleSid }) => {
  const [showMappings, setShowMappings] = useState(false)

  const startConnectorMappings = mappings['start'] || []
  const endConnectorMappings = mappings['end'] || []
  const devices = mappings['devices'] || []
  const mappingsCount = isEmpty(mappings) ? 0 : (startConnectorMappings.length + endConnectorMappings.length + devices.length)
  const editUrl = `/vehicles/${vehicleSid}/dtcs/${id}/edit`

  return (
    <div className='flex flex-row flex-wrap items-center bg-white w-full py-2 px-3 border-b border-tracer-dark-blue'>
      <div className='flex w-2/12 items-center'>
        <DtcRow dtcId={id} className='pl-2 py-2 float-left' />
        <span className='pl-5 break-all'>{name}</span>
      </div>
      <div className='w-6/12 pl-6 break-all'>{description}</div>
      <div className='w-1/12 pl-6 break-all'>{acronym}</div>
      <div className='w-1/12 pl-6'>{humanizeBool(active)}</div>
      <div className='w-1/12 pl-6 py-2'>{humanizeBool(destinationOnly)}</div>
      <div className='flex w-1/12 pl-2 block'>
        <div className='w-9/12'>
          {mappingsCount > 0 && (
            <span
              className="text-sm cursor-pointer inline-block text-blue-500 underline py-1"
              onClick={() => setShowMappings(!showMappings)}
            >
              {showMappings ? 'Hide' : 'Show'}, {mappingsCount}
            </span>
          )}
        </div>
        <div>
          <a href={editUrl}>
            <Edit size='2rem' color='#0189E9'/>
          </a>
        </div>
      </div>
      <div className={showMappings ? "w-full bg-gray-100 border border-gray-200 block flex flex-row mt-6 p-4 rounded" : 'hidden'}>
        <DtcMappings title='Start' mappings={startConnectorMappings} />
        <DtcMappings title='End' mappings={endConnectorMappings} />
        <DtcMappings title='Devices' mappings={devices} />
      </div>
    </div>
  )
}

DtcRowExtended.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  acronym: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  destinationOnly: PropTypes.bool.isRequired,
  mappings: PropTypes.object
}

import { connect } from 'react-redux'

const mapStateToProps = (state) => ({ vehicleSid: state.vehicle.sid })

export default connect(mapStateToProps)(DtcRowExtended)
