import React from 'react'
import PropTypes from 'prop-types'

const ConnectorInfo = ({field, onClick}) => (
  field ? (
    <span className='ml-3 cursor-pointer hover:text-gray-500' onClick={onClick}>
      {field}
    </span>
  ) : <span className='ml-3'>None</span>
)

ConnectorInfo.propTypes = {
  field: PropTypes.string,
  onClick: PropTypes.func.isRequired
}

export default ConnectorInfo
