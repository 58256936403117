import React, { useEffect } from 'react'

import DtcTable from './DtcTable'
// import DtcsValidation from './DtcsValidation'
import EditMappings from './EditMappings'
import Filters from './Filters'
// import LinkWithStatus from '../../shared/navigation/LinkWithStatus'
import LoadingSpinnerOverlay from '../../shared/LoadingSpinnerOverlay'
import Pagination from '../../shared/navigation/Pagination'

const TATMappings = ({ pagination, status, fetchDtcs, incrementPage, decrementPage}) => {
  // TODO: find better solution for this, we should trigger this update from reducer
  useEffect(() => { if (status === 'idle') fetchDtcs() }, [status])

  return (
    <div className='flex flex-col py-3 space-y-6'>
      <Filters />

      <DtcTable />

      <Pagination onClickNext={incrementPage} onClickPrev={decrementPage} context={pagination} />

      <div className='flex justify-between space-x-4 p-4 bg-tracer-dark-blue'>
        <EditMappings />
        {/* NOTE: temporary hide this buttons, as we plan to remove them at all, if nobody will request them */}
        {/*<DtcsValidation />*/}
        {/*<LinkWithStatus*/}
        {/*  className='btn'*/}
        {/*  confirmText='Are you sure?'*/}
        {/*  confirmTitle='DTCs will be synced'*/}
        {/*  href={`/vehicles/${sid}/dtcs/mappings/sync-tracer`}*/}
        {/*  title='Sync all DTC Mappings'*/}
        {/*/>*/}
      </div>

      {/*TODO: this should be moved on upper level and shouldn't be duplicated in each tab*/}
      <LoadingSpinnerOverlay active={status === 'loading'} />
    </div>
  )
}

TATMappings.propTypes = {}

import { connect } from 'react-redux'
import { incrementPage, decrementPage, fetchDtcs } from './tatMappingsSlice'

const mapStateToProps = ({ tatMappings: { pagination, status } }) => ({ pagination, status })

const mapDispatchToProps = (dispatch) => ({
  fetchDtcs: () => dispatch(fetchDtcs()),
  incrementPage: () => dispatch(incrementPage()),
  decrementPage: () => dispatch(decrementPage()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TATMappings)
