import { combineReducers } from 'redux'
import { omit } from 'ramda'
import {
  INIT_CONNECTORS,
  SELECT_CONNECTOR,
  MAP_CONNECTOR,
  SELECT_REFERENCE,
  CLEAR_SELECTED_REFERENCE,
  CLEAR_SELECTED_CONNECTOR,
  INIT_SVG,
  UPDATE_SVG,
  INIT_REFERENCES,
  UPDATE_REFERENCES
} from './actions'

function connectors(state = [], action) {
  switch (action.type) {
    case INIT_CONNECTORS:
      return action.connectors
      break

    case SELECT_CONNECTOR:
      return state.map(connector => {
        connector.selected = connector.name === action.connector.name
        return connector
      })
      break

    case MAP_CONNECTOR:
      return state.map(connector => {
        if (connector.name === action.connector.name) {
          if (action.reference !== null) {
            connector.edited = true
            connector.auto_mapped = false
            connector.reference = action.reference}
          else {
            connector.edited = false
            connector.auto_mapped = null
            connector.reference = action.connector.reference}
        }
        return connector
      })
      break;

    case CLEAR_SELECTED_CONNECTOR:
      return state.map(connector => omit(['selected'], connector))
      break

    default:
      return state
  }
}

function selectedReference(state = null, action) {
  switch (action.type) {
    case SELECT_REFERENCE:
      return action.reference
      break;

    case CLEAR_SELECTED_REFERENCE:
      return null
      break;

    default:
      return state
  }
}

function svg(state= [], action) {
  switch (action.type) {
    case INIT_SVG:
      return action.svg
      break;
    case UPDATE_SVG:
      return action.svg
      break;
    default:
      return state;
  }
}

function references(state= [], action) {
  switch (action.type) {
    case INIT_REFERENCES:
      return action.references
      break;
    case UPDATE_REFERENCES:
      return action.references
      break;
    default:
      return state;
  }
}


const state = combineReducers({
  connectors,
  selectedReference,
  svg,
  references
})
export default state
