import React from 'react'
import PropTypes from 'prop-types'
import ReferenceItem from './ReferenceItem'
import ReferenceSearch from './ReferenceSearch'
import ConnectorInfo from './ConnectorInfo';
import store from '../state/store'

class ReferenceSelector extends React.Component {
  constructor(props) {
    super(props)
    this.unsubscribe = store.subscribe(this.stateChange.bind(this))
    this.state = {
      selectedReference: store.getState().selectedReference,
      includeSearch: '',
      excludeSearch: ''
    }
  }

  stateChange() {
    this.setState({
      selectedReference: store.getState().selectedReference
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  includeHandler(e) {
    this.setState({
      ...(this.state),
      includeSearch: e.target.value.toUpperCase()
    })
  }

  excludeHandler(e) {
    this.setState({
      ...(this.state),
      excludeSearch: e.target.value.toUpperCase()
    })
  }

  pasteToIncludeSearch(arg) {
    this.setState({
      ...(this.state),
      includeSearch: arg
    })
  }

  render() {
    const { connector, onClick, unmappedReferences, sid, connectorViewId, authenticity_token } = this.props
    const { selectedReference, includeSearch, excludeSearch } = this.state

    let references = unmappedReferences
    if (connector.auto_mapped !== null) {
      references = [connector.reference, ...unmappedReferences]
    }
    if (includeSearch && includeSearch.trim()) {
      references = references.filter((ref) => ref.includes(includeSearch))
    }
    if (excludeSearch && excludeSearch.trim()) {
      references = references.filter((ref) => !ref.includes(excludeSearch))
    }
    return (
      <div className='flex flex-col flex-grow items-stretch'>
        <div className='flex'>
          <div className='text-white p-2 w-4/5'>
            <div className='font-bold'>
              {connector.name}
            </div>
            <div className='text-sm'>
              <div>
                {connector.description}
              </div>
              <div>
                Pins:
                {' '}
                {connector.cavities}
              </div>
              <div>
                FPN:
                <ConnectorInfo
                  field={connector.fpn}
                  onClick={() => this.pasteToIncludeSearch(connector.fpn)}
                />
              </div>
              <div>
                3D Reference:
                <ConnectorInfo
                  field={connector.reference}
                  onClick={() => this.pasteToIncludeSearch(connector.reference)}
                />
              </div>
              <div className='text-black'>
                <span className='text-white'>Include Search:</span>
                <ReferenceSearch
                  placeholder='Enter include value'
                  value={includeSearch}
                  onChange={(e) => this.includeHandler(e)}
                />
              </div>
              <div className='text-black'>
                <span className='text-white'>Exclude Search:</span>
                <ReferenceSearch
                  placeholder='Enter exclude value'
                  value={excludeSearch}
                  onChange={(e) => this.excludeHandler(e)}
                />
              </div>
            </div>
          </div>
          <span
            className='cursor-pointer my-4 underline text-white text-center w-1/5'
            onClick={() => onClick(connector, null)}
          >
            Clear
          </span>
        </div>

        <div className='mt-6 px-1 flex-auto overflow-auto h-0'>
          <div className='w-full bg-tracer-lt-blue text-white font-normal rounded-t pl-1'>
            Select 3D Reference
          </div>
          { references.length > 0 ? references.map(
              (ref, idx)=> (
                <ReferenceItem
                  reference={ref}
                  key={idx}
                  selected={ selectedReference === ref }
                  sid={sid}
                  connectorViewId={connectorViewId}
                  authenticity_token={authenticity_token}
                />
              ))
            : <div className='bg-white w-full px-2 py-3'>No entries found</div>
          }
        </div>
        <div className='btn my-4 w-full' onClick={() => onClick(connector, selectedReference)}>
          Apply
        </div>
      </div>
    )
  }
}

ReferenceSelector.propTypes = {
  connector: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  unmappedReferences: PropTypes.array.isRequired
}

export default ReferenceSelector
