import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import * as Routes from 'routes'

import flashStore from '../shared/alerts/state/store'
import { addFlashes } from '../shared/alerts/state/actions'

export const fetchPowerDistributions = createAsyncThunk(
  'powerDistributions/fetchPowerDistributions',
  async (payload, thunkAPI) => {
    const {
      powerDistributions: { pagination: { currentPage }, search },
      vehicle: { sid }
    } = thunkAPI.getState()
    const response = await axios.get(Routes.vehiclePowerDistributionsPath(sid, { page: currentPage, q: search, format: 'json' }))
    return response.data
  }
)

export const createExport = createAsyncThunk(
  'powerDistributions/createExport',
  async(payload, thunkAPI) => {
    const { vehicle: { sid } } = thunkAPI.getState()

    const response = await axios.post(Routes.vehiclePowerDistributionExportsPath(sid))

    return response.data
  }
)

export const fetchExport = createAsyncThunk(
  'powerDistributions/fetchExport',
  async (id, thunkAPI) => {
    const { vehicle: { sid } } = thunkAPI.getState()

    const response = await axios.get(Routes.vehiclePowerDistributionExportPath(sid, id))
    
    return response.data
  }
)

export const generateAllWires = createAsyncThunk(
  'powerDistributions/generateAllWires',
  async(payload, thunkAPI) => {
    const { vehicle: { sid } } = thunkAPI.getState()

    const response = await axios.post(Routes.generateAllWiresVehiclePowerDistributionsPath(sid))

    return response.data
  }
)

export const generateWires = createAsyncThunk(
  'powerDistributions/generateWires',
  async(id, thunkAPI) => {
    const { vehicle: { sid } } = thunkAPI.getState()

    const response = await axios.post(Routes.generateWiresVehiclePowerDistributionPath(sid, id))

    return response.data
  }
)

const powerDistributionsSlice = createSlice({
  name: 'powerDistributions',
  initialState: {
    powerDistributions: [],
    pagination: {
      currentPage: 1,
      totalCount: 1
    },
    status: 'idle',
    search: '',
    powerDistributionExport: null
  },
  reducers: {
    incrementPage: (state) => {
      if (state.pagination.currentPage < state.pagination.totalCount) {
        state.pagination.currentPage += 1
        state.status = 'idle'
      }
    },
    decrementPage: (state) => {
      if (state.pagination.currentPage > 1) {
        state.pagination.currentPage -= 1
        state.status = 'idle'
      }
    },
    setSearch: (state, action) => {
      state.search = action.payload
      state.pagination.currentPage = 1
    },
    clearPowerDistributionExport: (state) => {
      state.powerDistributionExport = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPowerDistributions.pending, (state) => { state.status = 'loading' })
      .addCase(fetchPowerDistributions.fulfilled,
        (state,
          { payload: { error, powerDistributions, pagination } }) => {
          if (error) {
            state.status = 'failed'
            flashStore.dispatch(addFlashes([{ body: error.message, type: 'error' }]))
            if (error.vehicle_is_blocked) {
              window.location = '/vehicles'
            } else {
              state.powerDistributions = []
              state.pagination.currentPage = 1
            }
          } else {
            state.status = 'succeeded'
            state.powerDistributions = powerDistributions
            state.pagination = pagination
          }
        })
      .addCase(fetchPowerDistributions.rejected, () => { window.location = '/vehicles' })
      .addCase(createExport.fulfilled, (state, { payload: { powerDistributionExport } }) => {
        state.powerDistributionExport = powerDistributionExport;
      })
      .addCase(createExport.rejected, () => { window.location = `/vehicles` })
      .addCase(fetchExport.fulfilled, (state, { payload: { powerDistributionExport } }) => {
        state.powerDistributionExport = powerDistributionExport;
      })
      .addCase(fetchExport.rejected, () => { window.location = `/vehicles` })
      .addCase(generateAllWires.fulfilled, (state, { payload: { message } }) => {
        flashStore.dispatch(addFlashes([{ body: message, type: 'success' }]))
      })
      .addCase(generateAllWires.rejected, () => { window.location = `/vehicles` })
      .addCase(generateWires.fulfilled, (state, { payload: { message } }) => {
        flashStore.dispatch(addFlashes([{ body: message, type: 'success' }]))
      })
      .addCase(generateWires.rejected, () => { window.location = `/vehicles` })
  }
})

export const {
  incrementPage,
  decrementPage,
  setSearch,
  clearPowerDistributionExport
} = powerDistributionsSlice.actions

export default powerDistributionsSlice.reducer
