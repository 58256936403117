import React from 'react'
import Select from 'react-select'

import CheckBox from '../../shared/CheckBox'

const Filters = ({
  ecus,
  filters: {active, destinationOnly, ecu, search, withConnectors},
  changeEcu,
  changeSearchText,
  triggerWithConnectorsFilter,
  triggerActiveFilter,
  triggerDestinationOnlyFilter,
  resetFilter
}) =>
  <div className='flex justify-between items-center text-white bg-tracer-dark-blue rounded py-3 px-6'>
    <div className='flex space-x-4 items-center'>
      <span>Filter</span>

      <input
        type='search'
        value={search}
        className='w-56 h-10 px-5 rounded focus:outline-none text-black'
        placeholder='Enter search query'
        onChange={changeSearchText}
      />

      <Select
        classNamePrefix='dtcs-filter'
        isMulti={false}
        value={ecus.filter(option => option.label === ecu)}
        options={ecus}
        placeholder='Select ECU'
        className='w-56 cursor-pointer'
        onChange={changeEcu}
      />

      <CheckBox checked={withConnectors} onChange={triggerWithConnectorsFilter}/>
      <span>Has no connector mappings</span>

      <CheckBox checked={active} onChange={triggerActiveFilter} />
      <span>Active</span>

      <CheckBox checked={destinationOnly} onChange={triggerDestinationOnlyFilter} />
      <span>Destination Only</span>
    </div>

    <a className='underline cursor-pointer' onClick={resetFilter}>Clear Filters</a>
  </div>

Filters.propTypes = {}

import { connect } from 'react-redux'
import { changeEcu, changeSearchText, triggerFilter, resetFilter } from './tatMappingsSlice'

const mapStateToProps = ({ tatMappings: { ecus, filters } }) => ({ ecus, filters })

const mapDispatchToProps = (dispatch) => ({
  changeEcu: (e) => dispatch(changeEcu(e.value)),
  changeSearchText: (e) => dispatch(changeSearchText(e.target.value)),
  triggerWithConnectorsFilter: () => dispatch(triggerFilter('withConnectors')),
  triggerActiveFilter: () => dispatch(triggerFilter('active')),
  triggerDestinationOnlyFilter: () => dispatch(triggerFilter('destinationOnly')),
  resetFilter: () => dispatch(resetFilter()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Filters)
