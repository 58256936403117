import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import {
  errorStyle, labelStyle, panelClass, rowClass, labelClass
} from './miscHelpers'

// Select which additional give user ability to filter option values by typing part of the text
// supports multi select, and even dynamically uploading list of options
// Built as wrapper around react-select component, parameters explanations
//  - errors: array of strings which will be shown under select
//  - label: text of title shown under select
//  - other params are sent to react-select as is, so for more details look into https://react-select.com/props
export const LookupableSelect = ({ errors, label, ...params }) => (
  <div className={rowClass}>
    <div className={labelClass} style={labelStyle}>{label}</div>
    <div className={panelClass}>
      <Select {...params} />
      {errors.map((message) => (<p style={errorStyle}>{message}</p>))}
    </div>
  </div>
)

LookupableSelect.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string
}

LookupableSelect.defaultProps = {
  errors: [],
  label: ''
}

export default LookupableSelect
