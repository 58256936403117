import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { panelStyle, buttonStyle } from '../shared/miscHelpers'
import { LookupableSelect } from '../shared/LookupableSelect'

const CsvDependenciesForm = ({updateUrl, authToken, csvDependencies, defaultCsvDependencies, errors}) => {
  const exclude = useCallback ((file) => (
    defaultCsvDependencies.filter((dep) => dep.value !== file)
  ), [defaultCsvDependencies])

  const defaultValues = useCallback ((arr) => (arr.map((a) => {
    const container = {}
    container.value = a
    container.label = `${a}.csv`
    return container
  })))

  return (
    <form action={updateUrl} method='POST'>
      <input type='hidden' name='authenticity_token' value={authToken} />
      <input type='hidden' name='_method' value='PATCH' />
      <div style={panelStyle} className='py-3'>
        { Object.entries(csvDependencies).map(([csvFile, dependencies], idx) => (
          <LookupableSelect
            errors={errors}
            name={`csv_dependencies[${csvFile}][]`}
            isMulti
            label={csvFile.concat('.csv')}
            options={exclude(csvFile)}
            defaultValue={defaultValues(dependencies)}
          />
        ))}
      </div>
      <input type='submit' style={{...buttonStyle, marginTop: '40px'}} value='Update CSV Dependencies' />
    </form>
  )
}

CsvDependenciesForm.propTypes = {
  csvDependencies: PropTypes.object.isRequired,
  updateUrl: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired
}

export default CsvDependenciesForm
