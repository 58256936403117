import React, { useEffect } from 'react'

import Actions from './Actions'
import Table from './Table'
import Pagination from '../../shared/navigation/Pagination'
import LoadingSpinnerOverlay from '../../shared/LoadingSpinnerOverlay'

const TracerMappings = ({ decrementPage, incrementPage, fetchTracerMappings, pagination, status }) => {
  // TODO: find better solution for this, we should trigger this update from reducer
  useEffect(() => { if (status === 'idle') fetchTracerMappings() }, [status, fetchTracerMappings])

  return (
    <div className='flex flex-col py-3 space-y-6'>
      <Actions />

      <Table />

      <Pagination onClickNext={incrementPage} onClickPrev={decrementPage} context={pagination} />

      <LoadingSpinnerOverlay active={status === 'loading'} />
    </div>
  )
}

TracerMappings.propTypes = {}

import { connect } from 'react-redux'
import { incrementPage, decrementPage, fetchTracerMappings } from './slice'

const mapStateToProps = ({ tracerMappings: { pagination, status } }) => ({ pagination, status })

const mapDispatchToProps = (dispatch) => ({
  fetchTracerMappings: () => dispatch(fetchTracerMappings()),
  incrementPage: () => dispatch(incrementPage()),
  decrementPage: () => dispatch(decrementPage()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TracerMappings)
