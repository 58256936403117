import React from 'react'
import PropTypes from 'prop-types'
import LinkWithStatus from './LinkWithStatus'

const Pagination = ({onClickNext, onClickPrev, context: {currentPage, totalCount, prevPageLink, nextPageLink}}) => {
  if (totalCount < 2) return null

  if (!prevPageLink && onClickPrev && currentPage >= 2) prevPageLink = 'void(0)'
  if (!nextPageLink && onClickNext && currentPage < totalCount) nextPageLink = 'void(0)'

  const navDesc = <span className='text-white px-5'>
                    Page <span className='font-bold'>{currentPage} of {totalCount}</span>
                  </span>

  const prevButton = prevPageLink ?
    (<LinkWithStatus href={prevPageLink} onClickFunc={onClickPrev} className='btn' title='Prev'/>) : ''
  const nextButton = nextPageLink ?
    (<LinkWithStatus href={nextPageLink} onClickFunc={onClickNext} className='btn' title='Next'/>) : ''

  return (
    <div className='flex w-full justify-center items-center'>
      {prevButton}
      {' '}
      {navDesc}
      {' '}
      {nextButton}
    </div>
  )
}

Pagination.propTypes = {
  context: PropTypes.object,
  onClickNext: PropTypes.func,
  onClickPrev: PropTypes.func,
}

Pagination.defaultProps = {
  context: {
    currentPage: 1,
    totalCount: 1
  }
}

export default Pagination
