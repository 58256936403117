import React from 'react'

import Row from './Row'

const Table = ({ tracerMappings }) => (
  <div className="flex flex-col tracer-mappings-container">
    <div className="flex rounded-t bg-tracer-lt-blue text-white px-3 text-sm font-light">
      <div className="w-1/4">
        DTC Name
      </div>
      <div className="w-2/4">
        Description
      </div>
      <div className="w-1/5">
        ECU
      </div>
      <div className="w-1/5">
        Destination Only?
      </div>
      <div className="w-1/5">
        Updated
      </div>
      <div className="w-1/12">
        Action
      </div>
    </div>
    { tracerMappings.map((tracerMapping, index) => <Row tracerMapping={tracerMapping} key={index} />) }
  </div>
)

Table.propTypes = {}

import { connect } from 'react-redux'

const mapStateToProps = ({ tracerMappings: { tracerMappings } }) => ({ tracerMappings })

export default connect(mapStateToProps)(Table)
