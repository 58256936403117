import React from 'react'
import PropTypes from 'prop-types'

class EditHarness extends React.Component{
  state = { searchQuery: this.props.searchQuery }

  setSearchQuery = (event) => {
    this.setState({ searchQuery: event.target.value })
  }

  render() {
    const { harnesses, searchPath } = this.props

    return (
      <div>Edit Harness</div>
    )
  }
}

EditHarness.propTypes = {
  searchQuery: PropTypes.string,
  searchPath: PropTypes.string,
  harnesses: PropTypes.array.isRequired
}

export default EditHarness
