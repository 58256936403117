import React from 'react'
import PropTypes from 'prop-types'
import SvgViewer from './SvgViewer'
import SvgViewerToolbar from "./SvgViewerToolbar"
import CodeMirror from '@uiw/react-codemirror'
import 'codemirror/theme/lucario.css'
import beautify from 'js-beautify'
import store from './state/store'
import { selectReference, mapConnector } from './state/actions'

class ConnectorImage extends React.Component {

  constructor(props) {
    super(props)
    this.unsubscribe = store.subscribe(this.stateChange.bind(this))
    const { connectors, selectedReference, svg } = store.getState()
    this.state = {
      connectors,
      selectedReference,
      svg,
      initialZoomLevel: null,
      displayGroups: false,
      displaySource: false,
      displayMappings: true,
      displayPng: true,
      fontSize: 20
    }

  }

  stateChange() {
    const { connectors, selectedReference, svg } = store.getState()
    this.setState({
      ...(this.state),
      connectors,
      selectedReference,
      svg
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  static defaultProps = {
    minZoomLevel: 0.3,
    zoomScaleSensitivity: 0.2
  }

  panZoom = null

  setFontSize = (size) => {
    this.setState({ ...(this.state), fontSize: size })
  }

  render() {
    const { src, background } = this.props

    const {
      displayGroups,
      displaySource,
      displayMappings,
      displayPng,
      svgSource,
      fontSize,
      connectors,
      selectedReference,
      svg
    } = this.state

    const mappedReferences = connectors
      .filter((c) => (c.auto_mapped !== null))
      .map((c) => c.reference)

    const selectedConnector = connectors.find((connector) => connector.selected)

    const onReferenceSelect = (ref) => {
      if (selectedConnector) {
        store.dispatch(selectReference(ref))
        store.dispatch(mapConnector(selectedConnector, ref))
      }
    }

    return (
      <div className='w-4/5 flex flex-grow flex-col items-stretch bg-tracer-darker-blue'>
        <SvgViewerToolbar
          onChange={this.onToolbarChange}
          displayGroups={displayGroups}
          displaySource={displaySource}
          displayMappings={displayMappings}
          displayPng={displayPng}
        />

        <div
          className={displaySource ? '' : 'hidden'}
          style={{ width: '100%', height: '100%'}}
        >
          <CodeMirror
            value={svgSource}
            options={{
              readOnly: true,
              mode: 'html',
              theme: 'lucario',
              foldGutter: true
            }}/>
        </div>

        <style>
          {`text
            {
              opacity: 0.2;
              font-size: ${fontSize}px;
              fill: white !important;
              cursor: pointer
            }
            .hl, circle
            {
              visibility: hidden;
            }
            g.hlOn .hl,
            g.selectedSvgElement .hl
            {
              visibility: visible;
            }
            g.hlOn text,
            g.selectedSvgElement text,
            g[id='${selectedReference}'] text
            {
              fill: yellow;
              stroke: yellow;
              opacity: 1;
              text-decoration: underline;
            }
            g.hlOn circle,
            g.selectedSvgElement circle,
            g[id='${selectedReference}'] circle
            {
              fill: none !important;
              stroke: yellow;
              opacity: 1;
            }
            g[id='${selectedReference}'] *           
            {
              fill: darkorange !important;
              stroke: darkorange !important;
              text-decoration: underline;
              font-weight: bold;
              font-size: ${fontSize * 2}px;
            } 
            g.mappedSvgElement text
            {
              fill: #DAF7A6;
              stroke: #DAF7A6;
              opacity: 1;
            }
            g.mappedSvgElement circle
            {
              fill: none !important;
              stroke: #DAF7A6;
              opacity: 1;
            }
            g[id='${selectedReference}'] text,
            g[id='${selectedReference}'] .hl,
            g.mappedSvgElement text,
            g.mappedSvgElement .hl,
            g[id='${selectedReference}'] circle,
            g.mappedSvgElement circle
            {
              visibility: visible;
            }`
          }
          { !displayMappings &&
              `g.mappedSvgElement text,
               g.mappedSvgElement .hl
               {
                 visibility: hidden;
               }`
          }
          { !displayGroups &&
            `text, circle
            {
              visibility: hidden;
            }`
          }
          { !displayPng &&
              `image
            {
              visibility: hidden;
            }`
          }
        </style>

        <SvgViewer
          hidden={displaySource}
          src={svg}
          background={background}
          panzoom
          onReady={this.onSvgReady}
          setFontSize={this.setFontSize}
          errorComponent={(<div>Failed to display svg</div>)}
          mappedReferences={mappedReferences}
          onReferenceSelect={onReferenceSelect}
        />
      </div>
    );
  }

  onToolbarChange = (key) => {
    this.setState({
      [key]: !this.state[key]
    })
  }

  onSvgReady = (svg, panZoom) => {
    this.resetState()

    // Max out the size of the svg container
    svg.setAttribute('width', '100%')
    svg.setAttribute('height', '100%')

    // Register panzoom events
    this.panZoom = panZoom
    this.panZoom.setBeforeZoom(this.onBeforeZoom)
    this.panZoom.setZoomScaleSensitivity(this.props.zoomScaleSensitivity)
    this.panZoom.setMinZoom(this.props.minZoomLevel)

    // Center the SVG and have it fit its container
    this.panZoom.resize()
    this.panZoom.contain()
    this.panZoom.center()

    const initialZoomLevel = this.panZoom.getZoom()

    this.setState(
      {
        svg,
        svgSource: beautify.html(svg.outerHTML),
        initialZoomLevel
      },
    )
  }

  initializeSvg = () => {
    const group = this.findGroupById('FC00AAL32335_008-CHART_-_WIRING_-_BODY__-__DT__QUAD___CREW__-__VP-B__STEP_5_OFFICIAL_36920185')
    this.displayGroup(group)
  }

  findGroupById = (id) => {
    const group = this.state.svg.getElementById(id)
    if (group) {
      return group
    } else {
      console.warn(`findGroupById Could not find element with ID ${id} in SVG`)
    }
  }

  displayGroup = (group) => {
    if (!group) {
      return
    }
    const boundingRect = group.getBoundingClientRect()

    const text = document.createElementNS("http://www.w3.org/2000/svg","text");
    text.setAttribute('x', boundingRect.x)
    text.setAttribute( 'y', boundingRect.y)
    text.setAttribute('fill', '#FFFFFF')
    text.setAttribute('opacity', '0.7')
    text.setAttribute('font-size', '18px')

    text.innerHTML = group.id
    group.appendChild(text)
  }

  onBeforeZoom = (prevZoom, newZoom) => {
    // TODO dynamically resize elements as needed...
    return
  }

  /**
   * Resets the state so that it is ready for a new SVG to be loaded.
   */
  resetState = () => {
    this.panZoom = null
    this.setState({
      defaultZoomPercentage: null,
      initialZoomLevel: null,
      prevZoomLevel: null,
      prevZoomPercentage: null,
      svg: null,
      zoomLevel: null,
      zoomPercentage: null
    })
  }
}

ConnectorImage.propTypes = {
  background: PropTypes.string.isRequired,
}

export default ConnectorImage
