import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import * as Routes from 'routes'
import flashStore from '../shared/alerts/state/store'
import { addFlashes } from '../shared/alerts/state/actions'

export const importFile = createAsyncThunk(
  'importers/importFile',
  async({ url, formData }) => {
    const headers = {
      headers: {
        'content-type': 'multipart/form-data',
        'accept': 'application/json'
      }
    }
    const response = await axios.post(url, formData, headers)
    return response.data
  }
)

export const generateWires = createAsyncThunk(
  'importers/generateWires',
  async (payload, thunkAPI) => {
    const { importers: { sid } } = thunkAPI.getState()
    const response = await axios.post(Routes.generateWiresVehicleDeviceConnectionsPath(sid))
    return response.data
  }
)

export const rollback = createAsyncThunk(
  'importers/rollback',
  async ({ fileType, importFileId }, thunkAPI) => {
    const headers = {headers: {'accept': 'application/json'}}

    const { importers: { sid } } = thunkAPI.getState()
    const response = await axios.post(Routes.rollbackVehicleImporterPath(sid, fileType, { import_file_id: importFileId }), {}, headers)
    return response.data
  }
)

export const fetchImportFile = createAsyncThunk(
  'importers/fetchImportFile',
  async ({ fileType, importFileId, actionType }, thunkAPI) => {
    const { importers: { sid } } = thunkAPI.getState()

    const config = {data: {}, headers: {accept: 'application/json'}}
    const response = await axios.get(Routes.vehicleImporterPath(sid, fileType, { import_file_id: importFileId, action_type: actionType }), config)
    
    return response.data
  }
)

const errorHandler = (error) => {
  if (error.vehicle_is_blocked)
    window.location = `/vehicles`
  else
    flashStore.dispatch(addFlashes([{ body: error, type: 'error' }]))
}

const importersSlice = createSlice({
  name: 'importers',
  initialState: {
    status: 'idle',
    importFiles: [],
    selected: {},
    loadingFile: {}
  },
  reducers: {
    initState: (state, { payload: { uploadUrl, sid, files } }) => {
      state.uploadUrl = uploadUrl
      state.sid = sid
      state.importFiles = files
    },
    addSelected: (state, { payload }) => {
      state.selected[payload.fileType] = payload.choice.value
    },
   setLoadingFile: (state, { payload }) => {
      if (state.loadingFile !== null)
        state.loadingFile[payload.fileType] = {
          status: payload.status,
          id: payload.importFileId,
          actionType: 'import',
          processingProgress: payload.processingProgress
        }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(importFile.fulfilled, (state, { payload: { error, success, importFileId, fileType } }) => {
        if (error) { errorHandler(error) }
        else if (success) {
          flashStore.dispatch(addFlashes([{ body: success, type: 'success' }]))
          state.loadingFile[fileType] = { status: 'pending', id: importFileId, actionType: 'import' }
        }
      })
      .addCase(importFile.rejected, () => { window.location = `/vehicles` })
      .addCase(generateWires.fulfilled, (state, { payload: { error, message } }) => {
        if (error) { flashStore.dispatch(addFlashes([{ body: error, type: 'error' }])) }
        else if (message) {
          flashStore.dispatch(addFlashes([{ body: message, type: 'success' }]))
        }
      })
      .addCase(generateWires.rejected, () => { window.location = `/vehicles` })
      .addCase(rollback.fulfilled, (state, { payload: { error, status, message, fileType, importFileId} }) => {
        if (error) { errorHandler(error) }
        else {
          state.selected[fileType] = ''
          state.loadingFile[fileType] = { status: 'loading', id: importFileId, actionType: 'rollback' }
          flashStore.dispatch(addFlashes([{ body: message, type: 'success' }]))
        }
      })
      .addCase(rollback.rejected, () => {
        window.location = `/vehicles`
      })
      .addCase(fetchImportFile.fulfilled, (state, { payload: { status, message, fileType, processingProgress, errorReason } }) => {
        if (status === null) {
          state.loadingFile = null
          flashStore.dispatch(addFlashes([{ body: message, type: 'success' }]))
          setTimeout(() => window.location.reload(), 5000)
        }
        else if (state.loadingFile[fileType].actionType === 'import' && ['finished', 'failed'].includes(status)){
          state.loadingFile = null
          flashStore.dispatch(addFlashes([{ body: message, type: 'success' }]))

          setTimeout(() => window.location.reload(), 5000)
        }
        else {
          if (message !== null) {
            const flashType = errorReason === null ? 'success' : 'error'
            flashStore.dispatch(addFlashes([{ body: message, type: flashType }]))
          }

          if (errorReason !== null) {
            state.loadingFile = null
            setTimeout(() => window.location.reload(), 5000)
          }
          else {
            state.loadingFile[fileType].status = status
            state.loadingFile[fileType].processingProgress = processingProgress
          }
        }
      })
      .addCase(fetchImportFile.rejected, () => {
        window.location = `/vehicles`
      })
  }
})

export const { initState, addSelected, setLoadingFile } = importersSlice.actions

export default importersSlice.reducer
