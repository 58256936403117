import React, { useState } from 'react'
import * as Routes from 'routes'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import Select, { components } from 'react-select'
import { Success, Warning } from './helperComponents'
import {rollback} from "./slice";

const { Option } = components
const StatusOption = (props) => (
  <Option {...props}>
    <div className='flex flex-row space-x-4'>
      <div>{props.data.label}</div>
      <div className='pt-1'>{props.data.statusSvg}</div>
    </div>
  </Option>
)
const DropdownActions = ({ downloadUrl, fileType, latestVersion }) => {
  const sid = useSelector(state => state.importers.sid)
  const selected = useSelector(state => state.importers.selected[fileType]) || ''
  const dispatch = useDispatch()
  const [selectedAction, setSelectedAction] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const importFileId = selected && (selected === 'all' ? '' : selected.id)

  const downloadPath = () => {
    window.location.href = `${downloadUrl}?import_file_id=${importFileId}`
  }

  const downloadLogs = () => {
    window.location.href = Routes.logsVehicleImporterPath(sid, selected.fileType, {import_file_id: importFileId})
  }

  const status = (selected && selected !== 'all') ? (selected.status === 'failed' ? <Warning /> : <Success />) : ('')

  const actionsMenu = [
    { value: 'download', label: importFileId ? 'Download Diff' : 'Download', isDisabled: (selected !== 'all' && selected !== latestVersion) },
    { value: 'rollback', label: 'Rollback', isDisabled: (selected !== latestVersion) },
    { value: 'logs', label: 'Logs', statusSvg: status, isDisabled: (selected === 'all' || selected === '' || !selected.importedAt) }
  ]

  const handleChange = (event) => {
    if (event.value === 'download'){
      downloadPath()
    } else if (event.value === 'logs'){
      downloadLogs()
    } else if (event.value === 'rollback'){
      setOpenModal(true)
    }
    setSelectedAction('')
  }

  const confirmRollback = (e) => {
    e.preventDefault()
    setOpenModal(false)
    dispatch(rollback({ fileType: selected.fileType, importFileId: selected.id }))
  }

  return (
    <>
      { openModal && (
      <div className='absolute z-10 min-w-full border border-2 rounded border-gray-300 bg-white mt-3'>
        <p className='p-3'>Are you sure you want to rollback import version from #{ selected.importedAt }?</p>
        <button
          className='btn py-1 px-2 m-2'
          onClick={(e) => confirmRollback(e)}>
          Yes, rollback it
        </button>
        <button
          className='btn py-1 px-2 m-2'
          onClick={() => setOpenModal(false)}>
          Cancel
        </button>
      </div>
    )}
    <div className='ml-2 mr-2' style={{minWidth: '125px'}}>
      <Select
        inputName={'blank'}
        isMulti={false}
        value={selectedAction}
        options={actionsMenu}
        placeholder='Actions'
        className='w-full'
        components={{ Option: StatusOption }}
        onChange={handleChange}
      />
    </div>
      </>
  )
}

DropdownActions.propTypes = {
  downloadUrl: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  latestVersion: PropTypes.object
}
export default DropdownActions
