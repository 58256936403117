import React from 'react'
import PropTypes from 'prop-types'
import LinkWithStatus from '../shared/navigation/LinkWithStatus'
import Pagination from '../shared/navigation/Pagination'
import LoadingSpinnerOverlay from '../shared/LoadingSpinnerOverlay'
import ConnectorViewRow from './ConnectorViewRow'
import * as Routes from 'routes'

class ConnectorViewsTable extends React.Component{
  state = {
    searchQuery: this.props.searchQuery,
    openedMappings: [],
    loading: false
  }

  toggleMappingActions = (mapping) => {
    let openedMappings = this.state.openedMappings
    if (openedMappings.includes(mapping.id)) {
      openedMappings = openedMappings.filter(m => m !== mapping.id)
    } else {
      openedMappings.push(mapping.id)
    }
    this.setState({ openedMappings: openedMappings })
  }

  mappingIsOpened = (mapping) => {
    return this.state.openedMappings.includes(mapping.id)
  }

  setSearchQuery = (event) => {
    this.setState({ searchQuery: event.target.value })
  }

  showLoader = (event) => {
    this.setState({ loading: true })
  }

  render() {
    const {
      connectorViews,
      pagination,
      sid
    } = this.props

    return (
      <div className='flex flex-col pt-6 flex-grow'>
        <LoadingSpinnerOverlay active={this.state.loading} />
        <div className='flex flex-row w-full flex-grow'>
          <div className='w-2/3'>
            <form
              className='flex flex-col w-full space-y-2 pb-5'
              action={Routes.vehicleConnectorViewsPath(sid)}
              acceptCharset='UTF-8'
              method='get'
            >
              <div className='flex space-x-5'>
                <div className='w-1/3'>
                  <input
                    autoComplete='off'
                    className='w-full bg-white h-10 px-5 pr-5 rounded focus:outline-none'
                    placeholder='Enter keyword'
                    type='search'
                    name='q[full_cont]'
                    value={this.state.searchQuery || ''}
                    onChange={e => this.setSearchQuery(e)}
                  />
                </div>
                <div className='w-2/3'>
                  <button className='btn'>Search</button>
                </div>
              </div>
            </form>
          </div>
          <div className='w-1/3 text-right'>
            <LinkWithStatus
              href={Routes.newVehicleConnectorViewPath(sid)}
              className={'cursor-pointer inline-block bg-tracer-lt-blue font-light hover:bg-blue-700 text-white py-2 px-3 rounded'}
              title={'New Vehicle View'}
            />
          </div>
        </div>
        <div className='flex flex-row bg-tracer-lt-blue rounded-t text-white px-3 text-sm font-light'>
          <div className='w-full px-2'>
            Vehicle Views
          </div>
        </div>
        { connectorViews.map((connectorView) => (
          <ConnectorViewRow
            key={connectorView.id}
            sid={sid}
            connectorView={connectorView}
            isOpen={this.mappingIsOpened(connectorView)}
            toggleMappingActions={this.toggleMappingActions}
            showLoader={this.showLoader}
          />
        ))}
        { pagination &&
          <div className='flex flex-col w-full space-y-2 py-6 text-center'>
            <Pagination context={pagination} />
          </div>
        }
      </div>
    )
  }
}

ConnectorViewsTable.propTypes = {
  searchQuery: PropTypes.string,
  connectorViews: PropTypes.array.isRequired,
  pagination: PropTypes.object,
}

export default ConnectorViewsTable
