import React, { useState } from 'react'
import * as Routes from 'routes'
import { Uploading } from '../importers/helperComponents'
import axios from 'axios'
import flashStore from '../shared/alerts/state/store'
import { addFlashes } from '../shared/alerts/state/actions'

const UploadSvgImage = ({ sid, id, svgImageFilename }) => {
  const [uploadingState, setUploadingState] = useState(false)
  const [filename, setFilename] = useState(null)

  // TODO: consider add useCallback to all functions to improve performance
  const buildFormData = (svgFile) => {
    const formData = new FormData()
    const { name, files } = svgFile.target
    formData.append('svg_image', files[0])
    return formData
  }

  const submit = (svgFile) => {
    const formData = buildFormData(svgFile)
    const actionUrl = Routes.uploadSvgVehiclePowerDistributionPath(sid, id)
    const headers = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return axios.post(actionUrl, formData, headers)
  }

  const handleValidationSuccess = (responseData) => {
    setFilename(responseData.svgImageFilename)
    flashStore.dispatch(
      addFlashes(
        [{ body: responseData.message, type: 'success' }]
      )
    )
  }

  const handleValidationError = (errors) => {
    flashStore.dispatch(
      addFlashes(
        [{ body: errors, type: 'error' }]
      )
    )
  }

  const toggleSpinner = () => {
    setUploadingState(!uploadingState)
  }

  const uploadFile = (e) => {
    toggleSpinner()
    submit(e)
      .then((response) => {
        const responseData = response.data

        if (responseData.error && responseData.error.length > 0) {
          handleValidationError(responseData.error)
        } else {
          handleValidationSuccess(responseData)
        }

        setUploadingState(false)
      })
  }

  const displayFilename = filename || svgImageFilename

  return (
    <>
      <div className='w-1/6 px-2 break-all'>
        {uploadingState && <Uploading />}
        {displayFilename !== '' ?
          <label htmlFor={id} className='inline-block text-blue-900 font-medium'>
            {displayFilename}
          </label> :
          <form>
            <label htmlFor={id} className={`cursor-pointer inline-block text-blue-500 underline ${uploadingState && 'hidden'}`}>
              Upload SVG image
            </label>
            <input
              type='file'
              name={id}
              id={id}
              onClick={(e) => (e.target.value = null)}
              onChange={uploadFile}
              style={{ position: 'absolute', opacity: 0, zIndex: -1 }}
            />
          </form>
        }
      </div>
    </>
  )
}

import { connect } from 'react-redux'

const mapStateToProps = ({ vehicle: { sid } }) => ({ sid })

export default connect(mapStateToProps)(UploadSvgImage)
