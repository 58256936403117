import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs'

import store from './store'
import { initialize } from './vehicleSlice'

import TATMappings from './TATMappings'
import TracerMappings from './TracerMappings'
import DTCMemos from './DtcMemos'
import 'stylesheets/components/dtcs/index'

const DtcApp = ({vehicle}) => {
  store.dispatch(initialize(vehicle))

  return (
    <Provider store={store}>
      <Tabs>
        <TabList>
          <Tab>TAT Mappings</Tab>
          <Tab>Tracer Mappings</Tab>
          <Tab>Memos</Tab>
        </TabList>

        <TabPanel>
          <TATMappings />
        </TabPanel>
        <TabPanel>
          <TracerMappings />
        </TabPanel>
        <TabPanel>
          <DTCMemos />
        </TabPanel>
      </Tabs>
    </Provider>
  )
}

DtcApp.propTypes = {
  vehicle: PropTypes.object.isRequired,
}

export default DtcApp
