import React from 'react'
import PropTypes from 'prop-types'
import PinRow from './PinRow'
import store from './store'
import {selectPin} from './actions'

class PinsTable extends React.Component {

  componentDidMount(){
    const selectedPins = this.props.selectedPins || []

    selectedPins.forEach((pin) => {
      store.dispatch(selectPin(pin))
    })
  }

  render() {
    const pins = this.props.pins

    return (
      <table className='w-full border-collapse'>
        <thead className='bg-tracer-lt-blue text-white font-normal'>
          <tr>
            <th className='rounded-tl'></th>
            <th className='font-normal'>Pin/Circuit</th>
            <th className='font-normal rounded-tr'>Description</th>
          </tr>
        </thead>
        <tbody className='bg-white'>
          {pins.map(pin => {
            return (
              <PinRow
                name={pin.name}
                value={pin.value}
                description={pin.description}
                key={pin.name}
              />
          )})}
        </tbody>
      </table>
    )
  }
}

PinsTable.propTypes = {
  pins: PropTypes.array.isRequired,
  selectedPins: PropTypes.array
}

export default PinsTable
