import React from 'react'

import DtcMemoRow from './DtcMemoRow'

const DtcMemosTable = ({ dtcMemos }) => (
  <div className="flex flex-col dtc-memos-container">
    <div className="flex rounded-t bg-tracer-lt-blue text-white px-3 text-sm font-light">
      <div className="w-1/5">
        ECU Acronym
      </div>
      <div className="w-1/5">
        DTC Name
      </div>
      <div className="w-3/4">
        Description
      </div>
      <div className="w-1/12">
        Action
      </div>
    </div>
    { dtcMemos.map((memo, index) => <DtcMemoRow memo={memo} key={index} />) }
  </div>
)

DtcMemosTable.propTypes = {}

import { connect } from 'react-redux'

const mapStateToProps = ({ dtcMemos: { dtcMemos } }) => ({ dtcMemos })

export default connect(mapStateToProps)(DtcMemosTable)
