import { combineReducers } from 'redux'
import {
  SELECT_PIN, DESELECT_PIN,
  SELECT_START_CONNECTOR,
  SELECT_END_CONNECTOR,
  SELECT_RELATED_CONNECTOR,
  DESELECT_RELATED_CONNECTOR,
  SELECT_CIRCUIT_ID,
  DESELECT_CIRCUIT_ID,
  SELECT_DB_LEVEL,
  SELECT_CODE_LEVEL,
  SELECT_SEARCH_OLD_LEVELS
} from './actions'
import { uniq } from 'ramda'

function selectedPins(state = [], action) {
  switch(action.type) {
    case SELECT_PIN:
      return uniq(state.concat(action.pin))
    case DESELECT_PIN:
      return state.filter(pin => pin !== action.pin)
    default:
      return state
  }
}

function selectedConnectorType(state = 'end', action) {
  switch (action.type) {
    case SELECT_START_CONNECTOR:
      return action.connectorType
    case SELECT_END_CONNECTOR:
      return action.connectorType
    default:
      return state
  }
}

function selectedRelatedConnectors(state = [], action) {
  switch (action.type) {
    case SELECT_RELATED_CONNECTOR:
      return uniq(state.concat(action.connectorId))
    case DESELECT_RELATED_CONNECTOR:
      return state.filter((connectorId) => connectorId !== action.connectorId)
    default:
      return state
  }

}

function selectedCircuitIds(state = [], action) {
  switch(action.type) {
    case SELECT_CIRCUIT_ID:
      return uniq(state.concat([action.circuitId]))
    case DESELECT_CIRCUIT_ID:
      return state.filter(circuitId => circuitId !== action.circuitId)
    default:
      return state
  }
}

function selectedQueryType(state = 'db_level', action) {
  switch (action.type) {
    case SELECT_DB_LEVEL:
      return action.queryType
    case SELECT_CODE_LEVEL:
      return action.queryType
    default:
      return state
  }
}

function selectedSearchOldLevels(state = false, action) {
  switch (action.type) {
    case SELECT_SEARCH_OLD_LEVELS:
      return action.value
    default:
      return state
  }
}

const state = combineReducers({
  selectedPins,
  selectedConnectorType,
  selectedRelatedConnectors,
  selectedCircuitIds,
  selectedQueryType,
  selectedSearchOldLevels
})

export default state
