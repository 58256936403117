import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'
import svgPanZoom from 'svg-pan-zoom'

const Loader = () => (
  <div className="text-white text-center text-2xl">Loading...</div>
)

const SelectedClass = 'selectedSvgElement'

class SvgViewer extends Component {
  panzoom = null
  svg = null

  static defaultProps = {
    hidden: false
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (typeof this.props.src === 'string') &&
      (typeof nextProps.src === 'string') &&
      nextProps.src !== this.props.src
  }

  render() {
    const srcIsInvalid = !this.props.src || this.props.src.includes('/null')
    const src = srcIsInvalid ? '' : this.props.src

    return (
        <div
          className={this.props.hidden ? 'hidden' : 'h-full w-full'}
          ref={container => (this.container = container)}
        >
          <SVG
            key="connector-view-svg-image"
            onError={this.onError}
            src={src}
            onLoad={this.onSvgLoadedInDom}
            preProcessor={this.svgPreProcessor}
            uniquifyIDs={false}
            cacheRequests={false}
            loader={<Loader />}
            innerRef={el => this.onSvgElementBind(el)}
          >
            {this.props.errorComponent}
          </SVG>
        </div>
    )
  }

  componentWillUnmount() {
    if (this.panzoom) this.panzoom.destroy()
  }

  onSvgElementBind = (el) => {
    if(!el) { return }

    const relSize = this.relSizeCalc(el)
    this.props.setFontSize(relSize/2)

    el &&
      el.querySelectorAll('text').
        forEach(
          f => {
            this.initTextNode(f, relSize)
            this.initCircle(f.parentElement.querySelector('circle'), relSize)
            this.elemIsMapped(f.parentElement) && this.markElementAsMapped(f.parentElement)

            f.parentElement.
              querySelectorAll('*:not(text)').
              forEach(
                f => this.elemIsMapped(f.parentElement) ?
                       this.initMappedElement(f, relSize)
                       :
                       this.dataInitAttrs(f, relSize)
              )

            f.addEventListener('click', e => this.textOnClick(e.target.parentElement, el))
            f.addEventListener('mouseover', e => this.textMouseOver(e.target.parentElement))
            f.addEventListener('mouseout', e => this.textMouseOut(e.target.parentElement))
          }
        )
  }

  relSizeCalc = (svg) => (
    (svg.viewBox.animVal.width + svg.viewBox.animVal.height)/200
  )

  initTextNode = (text, relSize) => {
    let posElement = text.parentElement.querySelector('polyline')
    let x, y

    if (posElement) {
      const points = posElement.points
      x = points[0].x
      y = points[1].y
    }

    if (!posElement){
      posElement = text.parentElement.querySelector('line')

      if (posElement) {
        x = posElement.x1.animVal.value
        y = posElement.y1.animVal.value
      }
    }

    if (posElement) {
      text.setAttribute('x', x + relSize)
      text.setAttribute('y', y + relSize)
    }

    text.textContent = text.parentElement.id
  }

  initCircle = (circle, relSize) => {
    let posElement = [...circle.parentElement.querySelectorAll('polyline')]
    let x, y, radius

    if (posElement) {
      const points = [].concat.apply([], posElement.map(el => this.parsePoints(el)))
      const xPoints = points.map(pt => pt[0])
      const yPoints = points.map(pt => pt[1])
      const maxX = Math.max(...xPoints)
      const minX = Math.min(...xPoints)
      const maxY = Math.max(...yPoints)
      const minY = Math.min(...yPoints)

      x = (maxX + minX) / 2
      y = (maxY + minY) / 2
      radius = maxX - minX
    }

    if (!posElement){
      posElement = circle.parentElement.querySelector('line')

      if (posElement) {
        radius = Math.abs(posElement.x2.animVal.value + posElement.x1.animVal.value)
        x = radius / 2
        y = (Math.abs(posElement.y2.animVal.value + posElement.y1.animVal.value)) / 2
      }
    }

    if (posElement) {
      circle.setAttribute('cx', x)
      circle.setAttribute('cy', y)
      circle.setAttribute('r', radius)
    }
  }

  parsePoints = (el) => [...el.points].map((pt) => [pt.x, pt.y])

  initMappedElement = (el, relSize) => {
    this.dataInitAttrs(el, relSize, '#DAF7A6')
  }

  textOnClick = (el, svg) => {
    if (this.elemIsMapped(el)) { return }

    this.props.onReferenceSelect(el.id)
  }

  textMouseOver = (el) => {
    el.setAttribute('class', `${el.getAttribute('class')} hlOn`)
  }

  textMouseOut = (el) => {
    el.setAttribute('class', el.getAttribute('class').replace(/hlOn/, ''))
  }

  elemIsMapped = (el) => (
    this.props.mappedReferences.includes(el.id)
  )

  markElementAsMapped = (el) => {
    el.setAttribute('class', 'mappedSvgElement')
  }

  setAttributes = (el, attrs) => {
    for(var key in attrs) {
      el.setAttribute(key, attrs[key])
    }
  }

  dataInitAttrs = (el, relSize, color = 'yellow') => {
    const newN = el.cloneNode()

    newN.setAttribute('class', 'hl')
    this.setHlAttrs(newN, relSize, color)
    el.after(newN)
  }

  setHlAttrs = (el, relSize, color) => {
    this.setAttributes(
      el,
      {
        'stroke': this.highlightValue(el, 'stroke', color),
        'fill': el.constructor.name === 'SVGCircleElement' ? 'none' : this.highlightValue(el, 'fill', color),
        'stroke-width': this.highlightValue(el, 'stroke-width', relSize*0.07),
        'style': 'opacity: 1',
      }
    )
  }

  highlightValue = (el, attr, newVal) => {
    return el.getAttribute(attr) === 'none' ? 'none' : newVal
  }

  svgPreProcessor = (code) => (
    this.addCircles(
      this.addTextTitles(
        this.replaceBackgroundImageUrl(code)
      )
    )
  )

  replaceBackgroundImageUrl = (svgSource) => (
    svgSource.replace(/xlink:href=["'][^"']*["']/gm, `xlink:href="${this.props.background}"`)
  )

  addTextTitles = (svgSource) => (
    svgSource.replace(/(<g[^>]+?>)([^<]*?)(<[^g>]+?>)/gm, "$1$2<text></text>$3")
  )

  addCircles = (svgSource) => (
    svgSource.replace(/(<g[^>]+?>)([^<]*?)(<[^g>]+?>)/gm, "$1$2<circle></circle>$3")
  )

  onSvgLoadedInDom = () => {
    this.svg = this.getSvgContainer()

    if (this.props.panzoom) {
      this.initializePanZoom(this.svg)
    }

    if (this.props.onReady) {
      this.props.onReady(this.svg, this.panzoom)
    }
  }

  getSvgContainer = () => {
    return this.container.getElementsByTagName('svg')[0]
  }

  initializePanZoom = svg => {
    this.panzoom = svgPanZoom(svg)
  }
}

SvgViewer.propTypes = {
  hidden: PropTypes.bool.isRequired,
  src: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  panzoom: PropTypes.bool.isRequired,
  onReady: PropTypes.func.isRequired,
  setFontSize: PropTypes.func.isRequired,
  errorComponent: PropTypes.object.isRequired,
  mappedReferences: PropTypes.array.isRequired,
  onReferenceSelect: PropTypes.func.isRequired,
}

export default SvgViewer
