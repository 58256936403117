import React from 'react'
import PropTypes from 'prop-types'
import {ProgresSpinner} from '../shared/CommonHelpers'

export const SELECT_ALL = 'SELECT_ALL'
export const DESELECT_ALL = 'DESELECT_ALL'

export const handleEnterKey = (evt, callback) => {
  if (evt.key === 'Enter') {
    evt.preventDefault()
    if (callback) {
      callback(evt)
    }
  }
}

export const LoadingLevelsSpinner = () => (
  <div className="mt-5 ml-2 text-white">
    <ProgresSpinner caption={'Loading Levels'} />
  </div>
)
