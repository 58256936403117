import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import * as Routes from 'routes'

import flashStore from '../../shared/alerts/state/store'
import { addFlashes } from '../../shared/alerts/state/actions'

export const importFromTracer = createAsyncThunk(
  'dtcMemos/importFromTracer',
  async (payload, thunkAPI) => {
    const { vehicle: { sid } } = thunkAPI.getState()

    const response = await axios.post(Routes.importFromTracerVehicleDtcMemosPath(sid))
    return response.data
  }
)

export const fetchDtcMemos = createAsyncThunk(
  'dtcMemos/fetchDtcMemos',
  async (payload, thunkAPI) => {
    const { dtcMemos: { pagination: { currentPage } }, vehicle: { sid } } = thunkAPI.getState()

    const response = await axios.get(Routes.vehicleDtcMemosPath(sid, {page: currentPage}))
    return response.data
  }
)

export const refreshMemo = createAsyncThunk(
  'dtcMemos/refreshMemo',
  async ({ id }, thunkAPI) => {
    const { vehicle: { sid } } = thunkAPI.getState()

    const response = await axios.post(Routes.refreshVehicleDtcMemoPath(sid, id))
    return response.data
  }
)

export const changeMemoStatus = createAsyncThunk(
  'dtcMemos/changeMemoStatus',
  async ({ id, status }, thunkAPI) => {
    if (!confirm('Are you sure?')) return

    const { vehicle: { sid } } = thunkAPI.getState()

    const response = await axios.patch(Routes.vehicleDtcMemoPath(sid, id, {status}))
    return response.data
  }
)

const errorHandler = (error, state) => {
  state.status = 'failed'
  flashStore.dispatch(addFlashes([{ body: error.message, type: 'error' }]))
  if (error.vehicle_is_blocked) window.location = `/vehicles`
}

const successHandler = (success, state) => {
  state.status = 'succeeded'
  flashStore.dispatch(addFlashes([{ body: success, type: 'success' }]))
}

const unexpectedHandler = (payload) => { alert(`Unexpected Response: ${payload}`) }

const dtcMemosSlice = createSlice({
  name: 'dtcMemos',
  initialState: {
    dtcMemos: [],
    openedMemos: [],
    pagination: {
      currentPage: 1,
      totalCount: 1,
    },
    status: 'idle'
  },
  reducers: {
    incrementPage: (state) => {
      if (state.pagination.currentPage < state.pagination.totalCount) {
        state.pagination.currentPage += 1
        state.openedMemos = []
        state.status = 'idle'
      }
    },
    decrementPage: (state) => {
      if (state.pagination.currentPage > 1) {
        state.pagination.currentPage -= 1
        state.openedMemos = []
        state.status = 'idle'
      }
    },
    triggerMemo: (state, { payload: { id } }) => {
      if (state.openedMemos.includes(id)) {
        state.openedMemos = state.openedMemos.filter((el) => el !== id)
      } else {
        state.openedMemos.push(id)
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDtcMemos.pending, (state) => { state.status = 'loading' })
      .addCase(fetchDtcMemos.fulfilled, (state, { payload: { error, dtcMemos, pagination } }) => {
        if (error) {
          state.dtcMemos = []
          state.pagination.currentPage = 1
          errorHandler(error, state)
        } else if (dtcMemos) {
          state.status = 'succeeded'
          state.dtcMemos = dtcMemos
          state.pagination = pagination
        } else unexpectedHandler(payload)
      })
      .addCase(fetchDtcMemos.rejected, () => { window.location = `/vehicles` })

      .addCase(refreshMemo.pending, (state) => { state.status = 'loading' })
      .addCase(refreshMemo.fulfilled, (state, { payload: { error, memo } }) => {
        if (error) errorHandler(error, state)
        else if (memo) {
          successHandler('DTC successfully assigned.', state)
          state.dtcMemos[state.dtcMemos.findIndex((el) => el.id === memo.id)] = memo
        } else unexpectedHandler(payload)
      })
      .addCase(refreshMemo.rejected, () => { window.location = `/vehicles` })

      .addCase(changeMemoStatus.pending, (state) => { state.status = 'loading' })
      .addCase(changeMemoStatus.fulfilled, (state, { payload: { error, success } }) => {
        if (error) { errorHandler(error, state) }
        else if (success) {
          successHandler(success, state)
          state.status = 'idle'
        } else unexpectedHandler(payload)
      })
      .addCase(changeMemoStatus.rejected, () => { window.location = `/vehicles` })

      .addCase(importFromTracer.pending, (state) => { state.status = 'loading' })
      .addCase(importFromTracer.fulfilled, (state, { payload: { error, success } }) => {
        if (error) { errorHandler(error, state) }
        else if (success) {
          successHandler(success, state)
          state.status = 'idle'
        } else unexpectedHandler(payload)
      })
      .addCase(importFromTracer.rejected, () => { window.location = `/vehicles` })
  }
})

export const { incrementPage, decrementPage, triggerMemo } = dtcMemosSlice.actions

export default dtcMemosSlice.reducer
