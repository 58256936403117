import React, { useCallback, useState } from 'react'
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import RowActions from './RowActions'

const renderTracerComponent = (missingComponents, component) => {
  if (!component)
    return null

  return(
    <div>
      <div className='underline'>{component.id}</div>
      <div>{component.description}</div>
      {missingComponents.includes(component.id) &&
        <div className='invalid-dtc-error'>Connector Needs to be created</div>
      }
    </div>
  );
}

const renderDtcStartConnector = (connector) => {
  if (!connector)
    return null

  return(
    <div>
      <div className='underline'>{connector[0]}</div>
      <div>{connector[1]}</div>
    </div>
  );
}

const Row = ({ tracerMapping, openedTracerMappings, triggerTracerMapping }) => {
  const hasValidDtc = tracerMapping.hasValidDtc
  const hasValidStartConnectors = tracerMapping.hasValidStartConnectors
  const hasValidEndConnectors = tracerMapping.hasValidEndConnectors
  const missingComponents = tracerMapping.missingComponents

  return(
    <div className='bg-white border-b border-gray-500'>
      <div className={`flex px-3 pt-3 ${hasValidDtc ? 'pb-3' : ''}`}>
        <div className='w-1/4'>
          { tracerMapping.dtcCode }
          { !hasValidDtc && <div className='invalid-dtc-error'> DTC Needs to be created</div> }
        </div>
        <div className='w-2/4'>{ tracerMapping.shortDesc }</div>
        <div className='w-1/5'>{ tracerMapping.ecuAcronym }</div>
        <div className='w-1/5'>{ tracerMapping.destinationOnly ? 'Yes' : 'No' }</div>
        <div className='w-1/5'>{ tracerMapping.updatedAt }</div>
        <div className='w-1/12'>
          <span
            className='inline-block text-blue-500 underline cursor-pointer'
            onClick={() => triggerTracerMapping(tracerMapping.id)}
          >
            Review
          </span>
        </div>
      </div>

      <SlideDown
        className='flex w-full border-b border-gray-200 px-3 py-2 items-start'
        style={{backgroundColor: '#F8FAFC'}}
        closed={!openedTracerMappings.includes(tracerMapping.id)}
      >
        { hasValidDtc &&
          <div className='w-1/2 border-r border-gray-300'>
            <div className='border-b p-2 border-gray-300 font-bold'>
              TAT Mapping
            </div>
            <div className="flex px-3">
              <div className="w-1/2 font-bold">
                { tracerMapping.tatDestinationOnly ? 'Destination Only' : 'Start:' }
              </div>
              <div className="w-1/2 font-bold">
                End:
              </div>
            </div>
            { tracerMapping.dtcEndConnectors.map((endConnector, index) => (
              <div className="flex px-3">
                <div className="w-1/2">
                  {renderDtcStartConnector(tracerMapping.dtcStartConnectors[index])}
                </div>
                <div className="w-1/2">
                  <div className='underline'>{endConnector[0]}</div><div>{endConnector[1]}</div>
                </div>
              </div>))
            }
            <br/>
            <div className="flex px-3">
              <div className="w-1/2 font-bold">
                Circuits:
              </div>
            </div>
            <div className="flex px-3">
              <div className="w-1/2 font-bold">
                {tracerMapping.circuits}
              </div>
            </div>
          </div>
        }
        
        <div className="w-1/2 border-gray-300">
          <div className="flex justify-between border-b p-2 border-gray-300">
            <div className="w-1/2 font-bold">
              Mapping from Tracer
            </div>
            {hasValidDtc && <RowActions tracerMapping={tracerMapping}/>}
          </div>
          <div className="flex px-3">
            <div className="w-1/2 font-bold">
              { tracerMapping.destinationOnly ? 'Destination Only' : 'Start:' }
            </div>
            <div className="w-1/2 font-bold">
              End:
            </div>
          </div>
          { tracerMapping.endComponents.map((endComponent, index) => (
            <div className="flex px-3">
              <div className="w-1/2">
                {renderTracerComponent(missingComponents, tracerMapping.startComponents[index])}
              </div>
              <div className="w-1/2">
                {renderTracerComponent(missingComponents, endComponent)}
              </div>
            </div>))
          }
        </div>
        {!hasValidDtc &&
          <div className="w-1/2 border-gray-300">
            <RowActions tracerMapping={tracerMapping}/>
          </div>
        }
      </SlideDown>
    </div>
  )
}

Row.propTypes = {}

import { connect } from 'react-redux'
import { triggerTracerMapping } from './slice'

const mapStateToProps = ({ tracerMappings: { openedTracerMappings } }) => ({ openedTracerMappings })

const mapDispatchToProps = (dispatch) => ({
  triggerTracerMapping: (id) => dispatch(triggerTracerMapping({ id })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Row)
