import React from 'react'

import LinkWithStatus from '../../shared/navigation/LinkWithStatus'

const ActionPanel = ({ importFromTracer }) => (
  <div className='bg-tracer-dark-blue rounded px-6 py-3'>
    <LinkWithStatus
      onClickFunc={importFromTracer}
      className='btn'
      title='Import All from Tracer'
    />
  </div>
)

ActionPanel.propTypes = {}

import { connect } from 'react-redux'
import { importFromTracer } from './dtcMemosSlice'

const mapStateToProps = ({ vehicle: { sid } }) => ({ sid })

const mapDispatchToProps = (dispatch) => ({
  importFromTracer: () => dispatch(importFromTracer())
})

export default connect(mapStateToProps, mapDispatchToProps)(ActionPanel)
