import React from 'react'
import { Provider } from 'react-redux'
import PropTypes from 'prop-types'
import store from './store'
import { initialize } from '../dtcs/vehicleSlice'
import PowerDistributions from './PowerDistributions'

const PowerDistributionApp = ({ vehicle }) => {
  store.dispatch(initialize(vehicle))

  return (
    <Provider store={store}>
      <PowerDistributions />
    </Provider>
  )
}

PowerDistributionApp.propTypes = {
  vehicle: PropTypes.object.isRequired
}

export default PowerDistributionApp
