import React from 'react'
import store from './store'

class FilterConnectorsToggleInputValue extends React.Component {
  constructor() {
    super()
    this.unsubscribe = store.subscribe(this.stateChange.bind(this))
    this.state = {
      queryType: store.getState().selectedQueryType
    }
  }

  stateChange() {
    this.setState({
      queryType: store.getState().selectedQueryType
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  render() {
    return(
      <input
        type={'hidden'}
        name={'query_type'}
        value={this.state.queryType}/>
    )
  }
}

export default FilterConnectorsToggleInputValue
