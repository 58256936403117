import React, {Component} from 'react'
import CheckBox from '../shared/CheckBox'

class SvgViewerToolbar extends Component {
  render() {
    return (
      <div className="text-white bg-tracer-chrome-blue text-xl p-5">
        <div className="flex">
          <b className="flex-initial">Layers:</b>
          <span className="flex w-1/4 items-center" >
            <div className="px-3">
              <CheckBox
                name="display_groups"
                value={this.props.displayGroups}
                checked={this.props.displayGroups}
                onChange={() => this.toggleCheckBox('displayGroups')}  />
            </div>
            <span>SVG Groups</span>
          </span>
          <span className="flex w-1/4 items-center">
            <div className="px-3">
              <CheckBox
                name="display_source"
                value={this.props.displaySource}
                checked={this.props.displaySource}
                onChange={() => this.toggleCheckBox('displaySource')} />
            </div>
            <span>SVG Source</span>
          </span>
          <span className=" flex w-1/4 items-center">
            <div className="px-3">
              <CheckBox
                name="display_mappings"
                value={this.props.displayMappings}
                checked={this.props.displayMappings}
                onChange={() => this.toggleCheckBox('displayMappings')} />
            </div>
            <span>Mappings</span>
          </span>
          <span className=" flex w-1/4 items-center">
            <div className="px-3">
              <CheckBox
                  name="display_png"
                  value={this.props.displayPng}
                  checked={this.props.displayPng}
                  onChange={() => this.toggleCheckBox('displayPng')} />
            </div>
            <span>PNG</span>
          </span>
        </div>
      </div>
    )
  }

  toggleCheckBox = (key) => {
    this.props.onChange(key)
  }
}

export default SvgViewerToolbar
