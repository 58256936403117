import { configureStore } from '@reduxjs/toolkit'
import vehicle from '../dtcs/vehicleSlice'
import powerDistributions from './slice'

export default configureStore({
  reducer: {
    vehicle,
    powerDistributions
  }
})
