import React from 'react'

const getUrl = (ids) => window.location.pathname + `/validations?dtc_id[]=${ids.join('&dtc_id[]=')}`

const DtcsValidation = ({ selectedDtcs }) =>
  <a
    href={getUrl(selectedDtcs)}
    onClick={(e) => (selectedDtcs.length < 1 ? e.preventDefault() : true)}
    className="btn"
  >
    Test Mappings for {selectedDtcs.length} DTCs
  </a>

DtcsValidation.propTypes = {}

import { connect } from 'react-redux'

const mapStateToProps = (state) => ({ selectedDtcs: state.tatMappings.selectedDtcs })

export default connect(mapStateToProps)(DtcsValidation)
