import React from 'react'
import {ProgresSpinner} from '../shared/CommonHelpers'

export const Warning = () => (
  <svg width="17" height="14" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M22 19L11 0L0 19H22ZM10 16V14H12V16H10ZM10 12H12V8H10V12Z" fill="#FFBE22"/>
  </svg>
)

export const Success = () => (
  <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.5 0C4.256 0 0 4.256 0 9.5C0 14.744 4.256 19 9.5 19C14.744 19 19 14.744 19 9.5C19 4.256 14.744 0 9.5 0ZM7.92857 14L4 10.1612L5.10786 9.0787L7.92857 11.8273L13.8921 6L15 7.09021L7.92857 14Z" fill="#1C9952"/>
  </svg>
)

export const Uploading = () => (
  <ProgresSpinner caption={'Loading'} />
)

export const Pending = () => (
  <ProgresSpinner caption={'Pending'} />
)
