import React from 'react'
import PropTypes from 'prop-types'

const FileInput = ({ fileFieldName, fileFieldId, onChange, labelTitle, isDisabled }) => (
  <>
    <label htmlFor={fileFieldId} className={`cursor-pointer inline-block ${isDisabled ? 'text-gray-500' : 'text-blue-500'} underline`}>
      {labelTitle}
    </label>
    <input
      type='file'
      name={fileFieldName}
      id={fileFieldId}
      onClick={e => (e.target.value = null)}
      onChange={onChange}
      style={{position: 'absolute', opacity: 0, zIndex: -1}}
      disabled={isDisabled || false}
    />
  </>
)

FileInput.propTypes = {
  fileFieldName: PropTypes.string.isRequired,
  fileFieldId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  labelTitle: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool
}

export default FileInput
