import { configureStore } from '@reduxjs/toolkit'
import tatMappings from './TATMappings/tatMappingsSlice'
import tracerMappings from './TracerMappings/slice'
import dtcMemos from './DtcMemos/dtcMemosSlice'
import vehicle from './vehicleSlice'

export default configureStore({
  reducer: {
    tatMappings,
    tracerMappings,
    dtcMemos,
    vehicle,
  },
})
