import React from 'react'

const DtcMappings = ({ title, mappings }) =>
  <div className='flex flex-row w-1/3'>
    <div className={`${title == 'Devices' ? 'w-1/5' : 'w-12'} font-bold text-sm`}>{title}:</div>
    <div className='w-5/6'>
      {mappings.map((mapping, index) => (
        <div key={index} className='mb-1'>
        <div className='underline'>{mapping.name}</div>
          <div className='text-sm'>{mapping.description}</div>
        </div>
      ))}
    </div>
  </div>

export default DtcMappings
