import React from 'react'
import PropTypes from 'prop-types'
import 'trix'
import 'trix/dist/trix.css'

const TrixEditor = ({
  id, input, value, onChange, style
}) => {
  const trixEditor = React.useRef(null)

  React.useEffect(() => {
    trixEditor.current.addEventListener('trix-change', () => {
      onChange(trixEditor.current.value)
    })
  }, [trixEditor])

  React.useEffect(() => {
    if (!trixEditor.current) return
    if (trixEditor.current.inputElement.value === value) return

    trixEditor.current.value = value
  }, [value])

  React.useEffect(() => {
    const preventFileUpload = (event) => event.preventDefault()
    trixEditor.current.addEventListener('trix-file-accept', preventFileUpload)

    return () => {
      trixEditor.current.removeEventListener('trix-file-accept', preventFileUpload)
    }
  }, [trixEditor])

  return (
    React.createElement('trix-editor', { ref: trixEditor, id, input, style })
  )
}

TrixEditor.propTypes = {
  id: PropTypes.string,
  input: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.string
}

export default TrixEditor
