import React, { useCallback } from 'react'
import store from './store'
import { FILTER_CONNECTORS_QUERY_TYPE, selectDbLevel, selectCodeLevel } from './actions'
import ButtonToggle from 'components/shared/ButtonToggle'

const FilterConnectorsToggle = ({selectedValue}) => {
  const selectStart = useCallback((e) => {
    store.dispatch(selectDbLevel())
  }, [store, selectDbLevel])

  const selectEnd = useCallback((e) => {
    store.dispatch(selectCodeLevel())
  }, [store, selectCodeLevel])

  return (
    <ButtonToggle
      className={'flex items-stretch px-6 my-4'}
      selectedValue={selectedValue}
      startValue={FILTER_CONNECTORS_QUERY_TYPE.dbLevel}
      endValue={FILTER_CONNECTORS_QUERY_TYPE.codeLevel}
      selectStart={selectStart}
      selectEnd={selectEnd}
      startButtonTitle={'DB level'}
      endButtonTitle={'Code level'}
    />
  )
}

export default FilterConnectorsToggle
