import React from 'react'

const RowActions = ({tracerMapping, changeTracerMappingStatus, refreshTracerMapping}) => {
	const hasValidDtc = tracerMapping.hasValidDtc
  const validToAccept = hasValidDtc && tracerMapping.hasValidStartConnectors && tracerMapping.hasValidEndConnectors

  return(
    <div className={`font-medium cursor-pointer ${!hasValidDtc ? 'float-right' : ''} `}>
      { validToAccept ?
        <span
          className='accept-btn'
          onClick={() => changeTracerMappingStatus(tracerMapping.id, 'accept')}
        >
          Accept
        </span>
        :
        <span
          className='refresh-btn'
          onClick={() => refreshTracerMapping(tracerMapping.id)}
        >
          Refresh
        </span>
      }
      <span
        className='reject-btn'
        onClick={() => changeTracerMappingStatus(tracerMapping.id, 'reject')}
      >
        Reject
      </span>
    </div>
  )
}

RowActions.propTypes = {}

import { connect } from 'react-redux'
import { changeTracerMappingStatus, refreshTracerMapping } from './slice'

const mapDispatchToProps = (dispatch) => ({
  refreshTracerMapping: (id) => dispatch(refreshTracerMapping({ id })),
  changeTracerMappingStatus: (id, status) => dispatch(changeTracerMappingStatus({ id, status }))
})

export default connect(mapDispatchToProps)(RowActions)
