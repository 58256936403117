import React from 'react'
import { Provider } from 'react-redux'
import PropTypes from 'prop-types'
import store from './store'
import FilesForm from './FilesForm'
import { initState } from './slice'

const ImportersApp = ({ files, authToken, uploadUrl, sid }) => {
  store.dispatch(initState({ sid, uploadUrl, files }))
  return (
    <Provider store={store}>
      <FilesForm authToken={authToken} />
    </Provider>
  )
}

ImportersApp.propTypes = {
  files: PropTypes.array.isRequired,
  authToken: PropTypes.string.isRequired,
  uploadUrl: PropTypes.string.isRequired,
  sid: PropTypes.string.isRequired
}

export default ImportersApp
