import React from 'react'
import PropTypes from 'prop-types'

const DtcMappingRows = ({ mappings, category }) => (mappings.length > 0
  ? (
    <div className='flex flex-row'>
      <div className='w-16 font-bold text-sm'>
        {category}
        :
      </div>
      <div className='w-5/6'>
        {mappings.map((mapping, idx) => (
          <div key={idx} className='group hover:bg-blue-100'>
            <div className='group flex justify-between'>
              <span>{mapping.name}</span>
              <a
                href={mapping.url}
                className='text-blue-500 underline group-hover:visible invisible'
                rel='nofollow'
                data-method='delete'
                data-confirm='Are you sure?'
              >
                Delete
              </a>
            </div>
            <div className='group-hover:block hidden text-xs'>{mapping.description}</div>
          </div>
        ))}
      </div>
    </div>
  )
  : null)

DtcMappingRows.propTypes = {
  mappings: PropTypes.arrayOf(PropTypes.object).isRequired,
  category: PropTypes.string.isRequired
}

export default DtcMappingRows
