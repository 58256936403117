import React from 'react'
import { useSelector } from 'react-redux'
import PowerDistributionRow from './PowerDistributionRow'

const PowerDistributionsTable = ({ powerDistributions }) => {
  return (
    <div className='flex flex-col flex-grow'>
      <div className='flex flex-row bg-tracer-lt-blue rounded-t text-white px-3 text-sm font-light'>
        <div className='w-full px-2'>
          Power Distribution
        </div>
      </div>

      <div className='w-full'>
        { powerDistributions.map((powerDistribution) => (
          <PowerDistributionRow
            key={powerDistribution.id}
            {...powerDistribution}
          />
        ))}
      </div>
    </div>
  )
}

import { connect } from 'react-redux'

const mapStateToProps = ({ powerDistributions: { powerDistributions } }) => ({ powerDistributions })

export default connect(mapStateToProps)(PowerDistributionsTable)
