import React from 'react'
import PropTypes from 'prop-types'
import ItemRow from '../shared/ItemRow'
import store from './store'
import {selectPin, deselectPin, selectCircuitId, deselectCircuitId} from './actions'

class PinRow extends React.Component{
  constructor() {
    super()
    this.unsubscribe = store.subscribe(this.stateChange.bind(this))
    this.state = {
      selectedPins: store.getState().selectedPins,
    }
  }

  stateChange() {
    this.setState({
      selectedPins: store.getState().selectedPins,
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  componentDidMount() {
    const { checked, value } = this.props

    if (checked) {
      store.dispatch(selectPin(value))
    }
  }

  checkBoxHandler(evt) {
    const { value, circuitId } = this.props

    const checkboxValue = evt.target.checked
    if (checkboxValue) {
      store.dispatch(selectPin(value))
      store.dispatch(selectCircuitId(circuitId))
    }
    else {
      store.dispatch(deselectPin(value))
      store.dispatch(deselectCircuitId(circuitId))
    }
  }

  render() {
    const { name, value, description, harnessName, circuitId } = this.props

    const isChecked = this.state.selectedPins.indexOf(value) > -1
    const inputName = 'pins[]'

    return (
      <ItemRow
        name={name}
        inputName={inputName}
        value={value}
        onChange={(e) => this.checkBoxHandler(e)}
        description={description}
        isChecked={isChecked}
      >
        { harnessName && <div class='text-xs break-all'>
            HS: {harnessName}
          </div> }
      </ItemRow>
    )
  }
}

PinRow.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  description: PropTypes.string,
  harnessName: PropTypes.string,
  checked: PropTypes.bool,
  circuitId: PropTypes.string
}

export default PinRow
