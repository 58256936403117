import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import * as Routes from 'routes'

import flashStore from '../../shared/alerts/state/store'
import { addFlashes } from '../../shared/alerts/state/actions'

export const importFromTracer = createAsyncThunk(
  'tracerMappings/importFromTracer',
  async (payload, thunkAPI) => {
    const { vehicle: { sid } } = thunkAPI.getState()

    const response = await axios.post(Routes.importFromTracerVehicleTracerMappingsPath(sid))
    return response.data
  }
)

export const fetchTracerMappings = createAsyncThunk(
  'tracerMappings/fetchTracerMappings',
  async (payload, thunkAPI) => {
    const { tracerMappings: { pagination: { currentPage } }, vehicle: { sid } } = thunkAPI.getState()

    const response = await axios.get(Routes.vehicleTracerMappingsPath(sid, {page: currentPage}))
    return response.data
  }
)
export const refreshTracerMapping = createAsyncThunk(
  'tracerMappings/refreshTracerMapping',
  async ({ id }, thunkAPI) => {
    const { vehicle: { sid } } = thunkAPI.getState()

    const response = await axios.post(Routes.refreshVehicleTracerMappingPath(sid, id))
    return response.data
  }
)

export const changeTracerMappingStatus = createAsyncThunk(
  'tracerMappings/changeTracerMappingStatus',
  async ({ id, status }, thunkAPI) => {
    if (!confirm('Are you sure?')) return

    const { vehicle: { sid } } = thunkAPI.getState()

    const response = await axios.put(Routes.vehicleTracerMappingPath(sid, id, {status}))
    return response.data
  }
)

const errorHandler = (error, state) => {
  state.status = 'failed'
  flashStore.dispatch(addFlashes([{ body: error.message, type: 'error' }]))
  if (error.vehicle_is_blocked) window.location = `/vehicles`
}

const successHandler = (success, state) => {
  state.status = 'succeeded'
  flashStore.dispatch(addFlashes([{ body: success, type: 'success' }]))
}

const unexpectedHandler = (payload) => { alert(`Unexpected Response: ${payload}`) }

const slice = createSlice({
  name: 'tracerMappings',
  initialState: {
     tracerMappings: [],
     openedTracerMappings: [],
    pagination: {
      currentPage: 1,
      totalCount: 1,
    },
    status: 'idle'
  },
  reducers: {
    incrementPage: (state) => {
      if (state.pagination.currentPage < state.pagination.totalCount) {
        state.pagination.currentPage += 1
        state.openedTracerMappings = []
        state.status = 'idle'
      }
    },
    decrementPage: (state) => {
      if (state.pagination.currentPage > 1) {
        state.pagination.currentPage -= 1
        state.openedTracerMappings = []
        state.status = 'idle'
      }
    },
    triggerTracerMapping: (state, { payload: { id } }) => {
      if (state.openedTracerMappings.includes(id)) {
        state.openedTracerMappings = state.openedTracerMappings.filter((el) => el !== id)
      } else {
        state.openedTracerMappings.push(id)
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTracerMappings.pending, (state) => { state.status = 'loading' })
      .addCase(fetchTracerMappings.fulfilled, (state, { payload: { error, tracerMappings, pagination } }) => {
        if (error) {
          state.tracerMappings = []
          state.pagination.currentPage = 1
          errorHandler(error, state)
        } else if (tracerMappings) {
          state.status = 'succeeded'
          state.tracerMappings = tracerMappings
          state.pagination = pagination
        } else unexpectedHandler(payload)
      })
      .addCase(fetchTracerMappings.rejected, () => { window.location = `/vehicles` })

      .addCase(refreshTracerMapping.pending, (state) => { state.status = 'loading' })
      .addCase(refreshTracerMapping.fulfilled, (state, { payload: { error, connectorErrors, tracerMapping } }) => {
        if (error) errorHandler(error, state)
        if(connectorErrors) {
          connectorErrors.forEach(error => errorHandler(error, state))
        }
        else if (tracerMapping) {
          successHandler('DTC successfully assigned.', state)
          state.tracerMappings[state.tracerMappings.findIndex((el) => el.id === tracerMapping.id)] = tracerMapping
        } else unexpectedHandler(payload)
      })
      .addCase(refreshTracerMapping.rejected, () => { window.location = `/vehicles` })

      .addCase(changeTracerMappingStatus.pending, (state) => { state.status = 'loading' })
      .addCase(changeTracerMappingStatus.fulfilled, (state, { payload: { error, success } }) => {
        if (error) { errorHandler(error, state) }
        else if (success) {
          successHandler(success, state)
          state.status = 'idle'
        } else unexpectedHandler(payload)
      })
      .addCase(changeTracerMappingStatus.rejected, () => { window.location = `/vehicles` })

      .addCase(importFromTracer.pending, (state) => { state.status = 'loading' })
      .addCase(importFromTracer.fulfilled, (state, { payload: { error, success } }) => {
        if (error) { errorHandler(error, state) }
        else if (success) {
          successHandler(success, state)
          state.status = 'idle'
        } else unexpectedHandler(payload)
      })
      .addCase(importFromTracer.rejected, () => { window.location = `/vehicles` })
  }
})

export const { incrementPage, decrementPage, triggerTracerMapping } = slice.actions

export default slice.reducer
