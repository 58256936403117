import React from 'react'
import PropTypes from 'prop-types'
import ConnectorImage from "./ConnectorImage";
import ConnectorMappingForm from './ConnectorMappingForm/ConnectorMappingForm'
import store from './state/store'
import { initConnectors, initReferences, initSvg } from './state/actions'

class EditConnectorMappings extends React.Component {

  constructor(props) {
    super(props)
    store.dispatch(initConnectors(props.connectors))
    store.dispatch(initReferences(props.references))
    store.dispatch(initSvg(props.svg))
  }

  render() {
    const { png, formUrl, authenticity_token, sid, connectorViewId } = this.props

    return (
      <div className='flex flex-grow stretch-items overflow-hidden' style={{ height: 'calc(100vh - 64px)'}}>
        <ConnectorMappingForm
          authenticity_token={authenticity_token}
          formUrl={formUrl}
          sid={sid}
          connectorViewId={connectorViewId}
        />

        <ConnectorImage
          key={'connector-views-connector-image'}
          background={png}
        />
      </div>
    )
  }
}

EditConnectorMappings.propTypes = {
  svg: PropTypes.string.isRequired,
  png: PropTypes.string.isRequired,
  references: PropTypes.array.isRequired,
  connectors: PropTypes.array.isRequired,
  authenticity_token: PropTypes.string.isRequired,
  formUrl: PropTypes.string.isRequired
}

export default EditConnectorMappings
