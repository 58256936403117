import React from 'react'
import store from './store'

class SearchOldLevelsInputValue extends React.Component {
  constructor() {
    super()
    this.unsubscribe = store.subscribe(this.stateChange.bind(this))
    this.state = {
      searchOldLevels: store.getState().selectedSearchOldLevels
    }
  }

  stateChange() {
    this.setState({
      searchOldLevels: store.getState().selectedSearchOldLevels
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  render() {
    return(
      <input
        type={'hidden'}
        name={'search_old_level'}
        value={this.state.searchOldLevels}/>
    )
  }
}

export default SearchOldLevelsInputValue
