import React, { useCallback, useState } from 'react'

import axios from 'axios'
import * as Routes from 'routes'
import flashStore from '../../shared/alerts/state/store'
import { addFlashes } from '../../shared/alerts/state/actions'
import store from '../state/store'
import { updateReferences, updateSvg } from '../state/actions'
import LoadingSpinnerOverlay from '../../shared/LoadingSpinnerOverlay'

const DuplicateSvgGroup = ({sid, connectorViewId, reference, authenticity_token}) => {
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback((e) => {
    e.preventDefault()
    const form = e.target
    const formData = new FormData(form)

    setLoading(true)
    axios.post(e.target.action, formData).then(response => {
      setLoading(false)
      const data = response.data

      if (data.error) {
        handleError(data)
      } else if (data.success) {
        handleSuccess(data)
      }
    })
  }, [])

  const handleError = useCallback((data) => {
    flashStore.dispatch(
      addFlashes(
        [{
          body: data.error,
          type: 'error'
        }]
      )
    )
  }, [])

  const handleSuccess = useCallback((data) => {
    store.dispatch(updateReferences(data.references))
    store.dispatch(updateSvg(data.svg))
    flashStore.dispatch(
      addFlashes(
        [{
          body: data.success,
          type: 'success'
        }]
      )
    )
  }, [])

  return (
    <div>
      <LoadingSpinnerOverlay active={loading} />
      <form
        action={Routes.duplicateSvgGroupVehicleConnectorViewPath(sid, connectorViewId)}
        acceptCharset='UTF-8'
        className='w-full'
        method='post'
        onSubmit={e => onSubmit(e)}
      >
        <input type='hidden' name='authenticity_token' value={authenticity_token} />
        <input type='hidden' name='reference' value={reference} />
        <button
          type='submit'
          className='object-left cursor-pointer text-blue-500 underline group-hover:block hidden font-normal w-full px-2'
        >
          Duplicate
        </button>
      </form>
    </div>
  )
}

export default DuplicateSvgGroup
