import React from 'react'
import PropTypes from 'prop-types'
import 'react-slidedown/lib/slidedown.css'
import axios from 'axios'
import Pagination from '../shared/navigation/Pagination'
import { handleEnterKey } from './utils'

class HarnessesSearchTable extends React.Component{
  state = {
    searchQuery: this.props.searchQuery,
    harnesses: [],
    pagination: {}
  }

  componentDidMount = () => {
    this.fetchHarnesses()
  }

  fetchHarnesses = (page) => {
    const { vehicle } = this.props
    const { searchQuery } = this.state

    axios.get(`/vehicles/${vehicle.sid}/harnesses.json`, {
      params: {
        'q[full_cont]': searchQuery,
        page: page
      }
    })
    .then((response) => {
      const data = response.data
      if (data.error) {
        alert(data.error.message)
        if (data.error.vehicle_is_blocked) {
          window.location = `/vehicles`
        }
      } else {
        this.setState({
          harnesses: data.harnesses,
          pagination: data.pagination
        })
      }
    })
  }

  setSearchQuery = (event) => {
    this.setState( {searchQuery: event.target.value })
  }

  render() {
    const { onSelectHarness, selectedHarnessesIds } = this.props

    const {
      harnesses,
      searchQuery,
      pagination,
    } = this.state

    const onEnter = evt => {
      handleEnterKey(
        evt,
        () => (this.fetchHarnesses(pagination && pagination.currentPage))
      )
    }

    return (
      <div className='flex items-stretch flex-grow'>
        <div className='flex-1 w-1/2 flex flex-col bg-tracer-darker-blue px-4 pb-4 flex-grow'>
          <div className='text-white mb-6'>
            <div className='flex'>
              Select Harness
            </div>
          </div>
          <div className='w-full'>
            <form
              className='flex flex-col w-full space-y-2 pb-5'
              action={''}
              acceptCharset='UTF-8'
              method='get'
            >
              <div className='flex space-x-5'>
                <div className='w-2/3'>
                  <input
                    autoComplete='off'
                    className='w-full bg-white h-10 px-5 pr-5 rounded focus:outline-none'
                    placeholder='Enter Harness Name'
                    type='search'
                    name='q[full_cont]'
                    value={searchQuery || ''}
                    onChange={e => this.setSearchQuery(e)}
                    onKeyDown={onEnter}
                  />
                </div>
                <div className='w-1/3'>
                  <span className='btn' onClick={e => this.fetchHarnesses(pagination && pagination.currentPage)}>
                    Search
                  </span>
                </div>
              </div>
            </form>
          </div>
          <div className='flex-auto overflow-auto h-56'>
            { harnesses.filter(({ id }) => !selectedHarnessesIds.includes(id)).map((h, i) => (
              <div
                key={i}
                className='flex flex-row items-center bg-white w-full cursor-pointer'
                onClick={() => onSelectHarness(h)}
              >
                <div className='flex flex-row items-center bg-white w-full px-4 py-3 border-b border-tracer-dark-blue'>
                  <div className='font-bold'>
                    {h.name}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Pagination
            onClickNext={() => this.fetchHarnesses(pagination.currentPage + 1)}
            onClickPrev={() => this.fetchHarnesses(pagination.currentPage - 1)}
            context={pagination}
          />
        </div>
      </div>
    )
  }
}

HarnessesSearchTable.propTypes = {
  vehicle: PropTypes.object.isRequired,
  onSelectHarness: PropTypes.func.isRequired,
  selectedHarnessesIds: PropTypes.array
}

export default HarnessesSearchTable
