import React from 'react'
import PropTypes from 'prop-types'
import MappingItem from './MappingItem'
import ReferenceSelector from './ReferenceSelector'
import Form from './Form'
import store from '../state/store'
import {
  selectConnector,
  mapConnector,
  selectReference,
  clearSelectedReference,
  clearSelectedConnector
} from '../state/actions'

class ConnectorMappingForm extends React.Component {

  constructor(props) {
    super(props)
    this.unsubscribe = store.subscribe(this.stateChange.bind(this))

    this.state = {
      activeTab: 'pending',
      referenceSelectorVisible: false,
      ...store.getState()
    }
  }

  stateChange() {
    this.setState({
      ...(this.state),
      connectors: store.getState().connectors,
      references: store.getState().references
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  toggleTabs = () => {
    const activeTab = this.state.activeTab === 'pending' ? 'autoMapped' : 'pending'
    this.setState({ ...(this.state), activeTab })
  }

  toggleRefSelector = () => {
    let { referenceSelectorVisible } = this.state
    referenceSelectorVisible = referenceSelectorVisible ? false : true

    this.setState({ ...(this.state), referenceSelectorVisible })
  }

  onSelectMapping(connector) {
    store.dispatch(clearSelectedReference())
    store.dispatch(selectConnector(connector))
    if (connector.reference) {
      store.dispatch(selectReference(connector.reference))
    }
    this.toggleRefSelector()
  }

  updateMapping(connector, ref) {
    store.dispatch(mapConnector(connector, ref))
    store.dispatch(clearSelectedConnector())
    this.toggleRefSelector()
  }

  render() {
    const { authenticity_token, formUrl, sid, connectorViewId } = this.props

    const {
      activeTab,
      referenceSelectorVisible,
      connectors,
      references,
    } = this.state

    const selectedConnector = connectors.find(ctr => ctr.selected)

    const unmappedRefs = references.filter((ref)=> (
      !connectors.map(c => c.reference).includes(ref)
    ))

    let pendingLink, autoMappedLink, connectorsToRender
    const showRefSelector = selectedConnector && referenceSelectorVisible;
    const pendingConnectors = connectors.filter(ctr => ctr.auto_mapped === null)
    const mappedConnectors = connectors.filter(ctr => ctr.auto_mapped !== null)

    const highLighted = 'font-bold text-white border-white'
    const faded = 'font-normal text-gray-600 border-gray-600'

    if (activeTab === 'pending') {
      autoMappedLink = faded
      pendingLink = highLighted
      connectorsToRender = pendingConnectors
    } else {
      autoMappedLink = highLighted
      pendingLink = faded
      connectorsToRender = mappedConnectors
    }

    return (
      <div className='w-1/5 items-stretch flex-grow flex flex-col'>
        { showRefSelector &&
          <ReferenceSelector
            connector={selectedConnector}
            unmappedReferences={unmappedRefs}
            onClick={this.updateMapping.bind(this)}
            sid={sid}
            connectorViewId={connectorViewId}
            authenticity_token={authenticity_token}
          />
        }

        <div className={`flex-grow flex-col flex items-stretch ${referenceSelectorVisible ? 'hidden' : ''}`}>
          <div className="w-full flex flex-row">
            <div
              onClick={this.toggleTabs}
              className={`border-b-2 p-4 w-1/2 text-center cursor-pointer ${pendingLink}`}
            >
              Pending({pendingConnectors.length})
            </div>
            <div
              onClick={this.toggleTabs}
              className={`border-b-2 p-4 w-1/2 text-center cursor-pointer ${autoMappedLink}`}
            >
              Mapped({mappedConnectors.length})
            </div>
          </div>

          <div className='mt-6 px-1 flex-auto overflow-auto h-0'>
            <div className="w-full bg-tracer-lt-blue text-white font-normal rounded-t pl-1">
              Select Connector
            </div>
            {
              connectorsToRender.map((connector, idx) => (
                <MappingItem
                  connector={connector}
                  key={idx}
                  onClick={this.onSelectMapping.bind(this)}
                />
              ))
            }
          </div>
          <div className='flex'>
            <Form
              authenticity_token={authenticity_token}
              connectors={mappedConnectors}
              formUrl={formUrl}
            />
          </div>
        </div>
      </div>
    )
  }
}

ConnectorMappingForm.propTypes = {
  authenticity_token: PropTypes.string.isRequired,
  formUrl: PropTypes.string.isRequired,
}

export default ConnectorMappingForm
