import React, { useState, useCallback } from 'react'

import CheckBox from '../shared/CheckBox'

const CreateDtcMappingsCheckbox = ({title, field, checked, onChange}) => {
  const [createDtcMappings, setCreateDtcMappings] = useState(checked || false)

  const onCheckboxChange = useCallback((e) => {
    setCreateDtcMappings(!createDtcMappings)
    if (onChange) onChange(!createDtcMappings)
  }, [setCreateDtcMappings, createDtcMappings])

  return (
    <span className='flex pt-4 items-center'>
      <CheckBox
        name={field}
        value={createDtcMappings}
        checked={createDtcMappings}
        onChange={onCheckboxChange}
      />
      <span className='pl-2 text-white'>{title}</span>
    </span>
  )
}

export default CreateDtcMappingsCheckbox
