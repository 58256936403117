import React, { useState, useCallback, useEffect } from 'react'

import store from './store'
import { selectSearchOldLevels } from './actions'
import CreateDtcMappingsCheckbox from './CreateDtcMappingsCheckbox'

const SearchOldLevelsCheckbox = ({checked}) => {
  useEffect(() => { onChange(checked) },[onChange, checked])

  const onChange = useCallback((value) => {
    store.dispatch(selectSearchOldLevels(value))
  }, [store, selectSearchOldLevels])

  return (
    <CreateDtcMappingsCheckbox
      title={'Search old levels'}
      field={'search_old_levels'}
      checked={checked}
      onChange={onChange}
    />
  )
}

export default SearchOldLevelsCheckbox
