import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import store from './store'
import { CONNECTOR_TYPE, selectEndConnector, selectStartConnector } from './actions'
import ButtonToggle from 'components/shared/ButtonToggle'

const ConnectorToggle = ({selectedValue}) => {
  const selectStart = useCallback((e) => {
    store.dispatch(selectStartConnector())
  }, [store, selectStartConnector])

  const selectEnd = useCallback((e) => {
    store.dispatch(selectEndConnector())
  }, [store, selectEndConnector])

  return (
    <ButtonToggle
      className={'flex items-stretch px-16 my-4'}
      selectedValue={selectedValue}
      startValue={CONNECTOR_TYPE.start}
      endValue={CONNECTOR_TYPE.end}
      selectStart={selectStart}
      selectEnd={selectEnd}
      startButtonTitle={'Start'}
      endButtonTitle={'End'}
    />
  )
}

export default ConnectorToggle
