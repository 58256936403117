import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

class VehicleConfig extends React.Component {
  state = {...this.props}

  configChangeHandler = (e) => {
    this.setState({ config: e.label })
  }

  render () {
    const { configs } = this.props
    const { config } = this.state

    return (
      <div className='flex w-full flex-col space-y-2'>
        <div className='flex space-x-6'>
          <div className='flex items-center space-x-4'>
            <div className='flex w-3/4 cursor-pointer'>
              <Select
                name={'vehicle_config_id'}
                classNamePrefix='dtcs-filter'
                isMulti={false}
                value={configs.filter(option => option.label === config)}
                options={configs}
                placeholder={'select'}
                className='w-full'
                onChange={this.configChangeHandler}
              />
            </div>
            <div className='flex content-center w-1/4'>
              <span className='select-none text-white'>Vehicle Config</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

VehicleConfig.propTypes = {
  config: PropTypes.string,
  configs: PropTypes.array.isRequired
}

export default VehicleConfig
