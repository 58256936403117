import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import LoadingSpinnerOverlay from '../LoadingSpinnerOverlay'

const LinkWithStatus = (props) => {
  const {
    href,
    className,
    style,
    title,
    confirmText,
    confirmTitle,
    onClickFunc
  } = props

  const [active, activate] = useState(false)

  const resolveOnClick = () => {
    if (onClickFunc) {
      onClickFunc()
      activate(false)
    } else {
      window.location.href = href
    }
  }

  useEffect(() => {
    if (active) {
      resolveOnClick()
    }
  }, [active]);

  const onConfirm = () => {
    activate(true, resolveOnClick)
  }

  const onClose = () => {
    swal.close()
  }

  const showConfirmationAlert = () => {
    swal
      .fire({
        title: (confirmTitle || 'Action confirmation'),
        text: confirmText,
        focusConfirm: false,
        cancelButtonText: 'Cancel',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: 'rgb(245, 80, 80)',
        allowOutsideClick: true
      })
      .then((result) => {
        if (result.value) {
          onConfirm()
        } else if (result.dismiss === swal.DismissReason.cancel) {
          onClose()
        }
      })
  }

  const onClick = () => {
    if (confirmText) {
      showConfirmationAlert()
    } else {
      onConfirm()
    }
  }

  return (
    <>
      <span
        className={`cursor-pointer ${className}`}
        style={style}
        onClick={onClick}
      >
        {props.children || title}
      </span>
      <LoadingSpinnerOverlay
        active={active}
      />
    </>
  )
}

LinkWithStatus.propTypes = {
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.string,
  title: PropTypes.string,
  confirmText: PropTypes.string,
  confirmTitle: PropTypes.string,
  onClickFunc: PropTypes.func
}

export default LinkWithStatus
