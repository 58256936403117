import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import * as Routes from 'routes'

import flashStore from '../../shared/alerts/state/store'
import { addFlashes } from '../../shared/alerts/state/actions'


const initialState = {
  dtcs: [],
  ecus: [],
  selectedDtcs: [],
  status: 'idle',
  filters: {
    active: true,
    destinationOnly: false,
    ecu: null,
    search: '',
    withConnectors: true
  },
  pagination: {
    currentPage: 1,
    totalCount: 1,
  }
}

export const fetchDtcs = createAsyncThunk(
  'tatMappings/fetchDtcs',
  async (payload, thunkAPI) => {
    const { tatMappings: { filters, pagination: { currentPage } }, vehicle: { sid } } = thunkAPI.getState()

    const response = await axios.get(Routes.vehicleDtcsPath(sid, {filters, page: currentPage, format: 'json'}))
    return response.data
  }
)

const tatMappingsSlice = createSlice({
  name: 'tatMappings',
  initialState,
  reducers: {
    incrementPage: (state) => {
      if (state.pagination.currentPage < state.pagination.totalCount) {
        state.pagination.currentPage += 1
        state.status = 'idle'
      }
    },
    decrementPage: (state) => {
      if (state.pagination.currentPage > 1) {
        state.pagination.currentPage -= 1
        state.status = 'idle'
      }
    },

    changeSearchText: (state, { payload }) => {
      state.filters.search = payload
      state.pagination.currentPage = 1

      const termLength = state.filters.search.length
      if (termLength === 0 || termLength > 1) state.status = 'idle'
    },
    changeEcu: (state, { payload }) => {
      state.filters.ecu = payload
      state.pagination.currentPage = 1
      state.status = 'idle'
    },
    triggerFilter: (state, { payload }) => {
      state.filters[payload] = !state.filters[payload]
      state.pagination.currentPage = 1
      state.status = 'idle'
    },
    resetFilter: (state) => {
      state.filters = initialState.filters
      state.pagination.currentPage = 1
      state.status = 'idle'
    },

    triggerDtc: (state, { payload }) => {
      if (state.selectedDtcs.includes(payload)) {
        state.selectedDtcs = state.selectedDtcs.filter(id => id !== payload)
      } else {
        state.selectedDtcs.push(payload)
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDtcs.pending, (state) => { state.status = 'loading' })
      .addCase(fetchDtcs.fulfilled, (state, { payload: { error, ecus, dtcs, pagination } }) => {
        if (error) {
          state.status = 'failed'
          flashStore.dispatch(addFlashes([{ body: error.message, type: 'error' }]))
          if (error.vehicle_is_blocked) {
            window.location = `/vehicles`
          } else {
            state.dtcs = []
            state.filters = initialState.filters
            state.pagination.currentPage = 1
          }
        } else {
          state.status = 'succeeded'
          state.dtcs = dtcs
          state.ecus = ecus
          state.pagination.currentPage = pagination.currentPage
          state.pagination.totalCount = pagination.totalCount
        }
      })
      .addCase(fetchDtcs.rejected, () => { window.location = `/vehicles` })
  }
})

export const {
  incrementPage,
  decrementPage,
  changeSearchText,
  changeEcu,
  triggerFilter,
  resetFilter,
  triggerDtc
} = tatMappingsSlice.actions

export default tatMappingsSlice.reducer
