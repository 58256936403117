import React, { useState, useCallback } from 'react'
import * as Routes from 'routes'
import UploadSvgImage from './UploadSvgImage'

const PowerDistributionRow = ({ sid, id, connector, svgImageFilename, wiresGenerated, generateWires }) => {
  const onGenerateWiresClick = useCallback ((e) => {
    e.preventDefault()
    generateWires(id)
  }, [])

  return (
    <div className='flex flex-row items-center bg-white py-2 px-3 border-b border-tracer-dark-blue'>
      <div className='w-3/6 px-2 break-all'>
        <div className='font-medium'>
          {connector}
        </div>
      </div>
      <UploadSvgImage id={id} svgImageFilename={svgImageFilename} />
      <div className='w-1/6 px-2 text-right'>
        {wiresGenerated ?
          <div className='font-medium'>Wires generated</div> :
          <a
            className='inline-block text-blue-500 underline'
            href='#'
            onClick={onGenerateWiresClick}
          >
            Generate Wires
          </a>
        }
      </div>
      <div className='w-1/6 px-2 text-right'>
        <a
          href={Routes.vehiclePowerDistributionPath(sid, id)}
          className='inline-block text-red-500 underline'
          rel='nofollow'
          data-method='delete'
          data-confirm='Are you sure? You will delete selected Power Distribution'
        >
          Delete
        </a>
      </div>
    </div>
  )
}

import { connect } from 'react-redux'
import { generateWires } from './slice'

const mapStateToProps = ({ vehicle: { sid } }) => ({ sid })

const mapDispatchToProps = (dispatch) => ({ generateWires: (id) => dispatch(generateWires(id)) })

export default connect(mapStateToProps, mapDispatchToProps)(PowerDistributionRow);
