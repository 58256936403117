import React from 'react'

import DtcRowExtended from './DtcRowExtended'

const DtcTable = ({ dtcs }) =>
  <div className='font-light'>
    <div className='flex flex-row w-full bg-tracer-lt-blue rounded-t-lg text-white px-3 text-sm'>
      <div className='flex w-2/12 pl-10 items-center'>DTC Name</div>
      <div className='flex w-6/12 pl-6 items-center'>Description</div>
      <div className='flex w-1/12 pl-6 items-center'>ECU</div>
      <div className='flex w-1/12 pl-6 items-center'>Active</div>
      <div className='w-1/12 pl-6'>Destination Only?</div>
      <div className="w-1/12 pl-6">Mappings, Qty</div>
    </div>

    {dtcs.map(dtc => <DtcRowExtended key={dtc.id} {...dtc} />)}
  </div>

DtcTable.propTypes = {}

import { connect } from 'react-redux'

const mapStateToProps = (state) => ({ dtcs: state.tatMappings.dtcs })

export default connect(mapStateToProps)(DtcTable)
